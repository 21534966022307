import React from 'react'
const Help = () => {
  return (
    <iframe
      id='inlineFrameExample'
      title='Inline Frame Example'
      style={{
        height: '90vh',
        width: '100%'
      }}
      src='https://aample.freshdesk.com/support/tickets/new'
    />
  )
}

export default Help
