import React from 'react'

const SampleFileInstructions = () => {
  return (
    <>
      <b>*  Indicates mandatory columns</b>
      <br />
      <br />
      <h1>
        Instructions to be followed while filling these fields
      </h1>
      <h3>
        TC Number
      </h3>
      <div>
        1.This column should contain transformer number given by section officer
      </div>
      <div>
        2. * Number can only contain numerical values and can not exceed 10 digits.
      </div>
      <div>
        3. * Same TC number can not be given to multiple DTCs
      </div>
      <br />
      <h3>
        TC Name
      </h3>
      1. This column should contain transformer name given by section officer
      <div>2. * Name should contain atleast 3 characters.</div>
      <div>3. * Same TC name can not be given to multiple DTCs</div>
      <br />
      <h3>
        Section
      </h3>
      <div>1. This should be the section to which the TC will belong</div>
      <div>2. This section has to be present in the system</div>
      <br />
      <h3>
        Reading day
      </h3>
      <div>1. This column should contain only be any one of given values</div>
      <div>{'\u00A0'}{'\u00A0'}1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 15</div>
      <br />

      <h3>
        MR tagging list
      </h3>
      <div>1.This column should contain the list of all MR codes whose installations are tagged to that DTC.</div>
      <b>
        <small>Important Note : All the MR Codes provided here should be present in the list of MRs in "MR file". Row will not be processed otherwise as it leads to error.</small>
      </b>
      <div>2.This can have multiple Values. MR code should be given in comma-separated format, if more than 1</div>
      <br />
      <h3>
        Reading MR
      </h3>
      <div>1. This column should contain only one MR code which belongs to  DTC reading MR.</div>
      <div>2. The MR code provided here should be present in the "MR tagging list" column.</div>
      <b><small>Important Note : The MR Code provided here should be present in the list of MRs in "MR file". Row will not be processed otherwise as it leads to error.</small></b>
      <br /><br />
      <h3>
        Type
      </h3>
      <div>1. This column should contain the type of DTC. It can only be either of one  given values</div>
      <div>[ SELF_EXECUTION, DEPARTMENT ]</div>
      <br />
      <h3>
        TIMS Code
      </h3>
      <div>1. This column should contain TIMS Code of the DTC</div>
      <div>2. Minimum allowed characters are : 3 and Maximum allowed characters are 30</div>
      <br />
      <h3>
        DTLMS Code
      </h3>
      <div>1. This column should contain DTLMS Code of the DTC</div>
      <div>2. Minimum allowed characters are : 3 and Maximum allowed characters are 30</div>
      <br />
      <h3>DTR Code</h3>
      <div>1.This column should contain DTR Code of the DTC</div>
      <div>2.Minimum allowed characters are : 3 and Maximum allowed characters are 30</div>
      <br />
      <h3>TC Make</h3>
      <div>1. This column should contain TC Make of the DTC</div>
      <div>2. Minimum allowed characters are : 3 and Maximum allowed characters are 30</div>
      <br />
      <h3>TC serial number</h3>
      <div>1. This column should contain TC Serial number of the DTC</div>
      <div>2. Minimum allowed characters are : 3 and Maximum allowed characters are 500</div>
      <br />
      <h3>Latitude, longitude</h3>
      <div>1. This column should contain the GPS location info of the DTC</div>
      <div>2. Can only take in lat long format</div>
      <br />
      <h3>Feeder MDM Code</h3>
      <div>1.This column should contain only one Feeder MDM code.</div>
      <b><small>Important Note : The Feeder info provided here should be present in the list of Feeder in "Power stations and feeders" file. Row will not be processed otherwise as it leads to error</small>.</b>
      <br />
      <br />
      <h3>TC Capacity in kVA</h3>
      <div>
        1. Transformer capacity. It can only be any one of given values
        [ 25, 63, 100, 160, 250, 315, 500, 990 ]
      </div>
      <br />
      <h3>Meter make</h3>
      <div>1. This column should contain TC Meter Make of the DTC</div>
      <div>2. Minimum allowed characters are : 3 and Maximum allowed characters are 30</div>
      <br />
      <h3>Meter serial number</h3>
      <div>1. This column should contain TC Meter Serial Number of the DTC</div>
      <div>2. Minimum allowed characters are : 3 and Maximum allowed characters are 30</div>
      <br />
      <h3>CT Ratio</h3>
      <div>1. CT Ration should be provided only in the format following format  : Primary current/ Secondary current</div>
      <div>2. It can only take positive numerical values</div>
    </>
  )
}

export default SampleFileInstructions
