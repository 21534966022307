import React from 'react'
import styles from './fileHistoryHeader.module.scss'
import { useHistory } from 'react-router-dom'
import backIcon from '../../../assets/img/back-arrow-circle.png'
import RefreshButton from '../../atoms/RefreshButton/RefreshButton'
import filterIcon from '.././../../assets/img/filter.png'
import { fileStatusOptions, uploadedByOptions } from '../../../assets/offlineData/fileHistoryFilterOptions'
import closeIcon from '../../../assets/img/close-icon@2x.png'
import dayjs from 'dayjs'

const FileHistoryHeader = ({
  title = 'File',
  onRefresh,
  loading,
  onClickFilter,
  filtersApplied = {},
  onFilterRemoval = () => {}
}) => {
  const history = useHistory()

  const getLabel = (filterName, enumValue) => {
    if (filterName === 'fileStatus') {
      return fileStatusOptions.filter(option => option.value === enumValue)?.[0]?.label ?? '---'
    }
    if (filterName === 'uploadedBy') {
      return uploadedByOptions.filter(option => option.value === enumValue)?.[0]?.label ?? '---'
    }
    if (filterName === 'auditMonth') {
      return `Audit month : ${dayjs(enumValue).format('MMMM YYYY')}`
    }
  }

  return (
    <div>
      <div className={styles.header}>
        <img src={backIcon} alt='Missing Icon' onClick={() => history.goBack()} />
        <div className={`ms-3 ${styles.heading}`}>
          {title} | File upload history
        </div>

        <button
          onClick={onClickFilter}
          type='button'
          className={`btn ${styles.filterBtn}`}
        >
          <img
            src={filterIcon}
            alt='missing icon'
            className=''
            width='8'
            height='8'
          />
          Filter
        </button>
        <RefreshButton onRefresh={onRefresh} loading={loading} />
      </div>
      <div className={styles.filterPillsContainer}>
        {Object.keys(filtersApplied).map(filterName => {
          return ((filtersApplied[filterName] !== '' && filtersApplied[filterName] !== undefined) &&
           (
             <div key={filterName} className={styles.filterPills}>
               {getLabel(filterName, filtersApplied[filterName])}
               <img
                 alt='Close Icon'
                 src={closeIcon}
                 className={styles.closeIcon}
                 onClick={() => { onFilterRemoval(filterName, filtersApplied[filterName]) }}
               />
             </div>)
          )
        }
        )}
      </div>
    </div>
  )
}

export default FileHistoryHeader
