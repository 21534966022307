import React from 'react'
import styles from './resultItem.module.scss'
import allItems from '../../../assets/img/all-items.png'
import failure from '../../../assets/img/failure.png'
import skipItem from '../../../assets/img/skip-item.png'
import success from '../../../assets/img/success.png'
import infoIcon from '../../../assets/img/info-outlined.png'

const ResultItem = ({
  type = 'all',
  number = 100,
  onClickInfo
}) => {
  const resultItemData = {
    all: {
      name: 'All',
      icon: allItems
    },
    success: {
      name: 'Success',
      icon: success
    },
    failure: {
      name: 'Failure',
      icon: failure
    },
    skipped: {
      name: 'Skipped',
      icon: skipItem
    }
  }

  return (
    <div className={styles.root}>
      <img className={styles.icon} src={resultItemData[type].icon} alt='Missing Icon' />
      <div>
        <div className={styles.heading}>{resultItemData[type].name} :</div>
        <div className={styles.content}>{number} Rows</div>
      </div>
      {(resultItemData[type].name === 'Skipped' || resultItemData[type].name === 'Failure') &&
        <img
          src={infoIcon}
          className={styles.infoIcon}
          alt='missing icon'
          onClick={onClickInfo}
        />}
    </div>
  )
}

export default ResultItem
