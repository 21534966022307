import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { SidebarData } from './SidebarData'
import downArrow from '../../assets/img/user-down-arrow.svg'
import upArrow from '../../assets/img/white-up-arrow.svg'
// import { fetchUserDesignation } from '../../services/utils/meta'

const SubMenu = (props) => {
  return (
    <ul className={`${props.isShowing ? '' : 'd-none'}`}>
      {
        props.menu.map((submenu, index) => {
          return (
            <li key={index}>
              <NavLink key={index} activeClassName='submenu-menu' to={submenu.link}>
                {submenu.title}
              </NavLink>
            </li>
          )
        })
      }
    </ul>
  )
}

const Sidebar = (props) => {
  const SidebarContent = SidebarData()
  const SidebarStyle = {
    height: 'calc(100vh - 79.6px)', // scroll-fix (prev change - height : 100%)
    width: '86px',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
  const SecondSidebarStyle = {
    height: 'calc(100vh - 79.6px)',
    position: 'fixed',
    display: props.sideBarMinimized ? 'none' : 'block',
    overflowX: 'hidden',
    overflowY: 'auto'
  }

  const [match, setMatch] = useState('')

  const subMenuHandler = (event, menu) => {
    if (menu.hasSubmenu) event.preventDefault()
    setMatch(menu.title)
  }

  // const loggedInUserDesignation = fetchUserDesignation()

  return (
    <>
      <aside className={`nav-vertical small-sidebar ${props.sideBarMinimized ? 'important' : ''}`} style={SidebarStyle}>
        <div className='nav-wrapper'>
          <div className='nav-inner-wrapper'>
            <ul>
              {
                SidebarContent.map((val, key) => {
                  // if (loggedInUserDesignation !== 'ae' && val.title === 'File upload') {
                  //   return ''
                  // }
                  return (
                    <div
                      key={key}
                      data-bs-toggle='tooltip' data-bs-placement='top' title={val.disabled}
                      style={{ cursor: val.disabled ? 'not-allowed' : 'auto' }}
                    >
                      <li>
                        <NavLink disabled={val.disabled} className='nav-link' to={val.link}>
                          <img src={val.icon} alt='sidebar icon' />
                        </NavLink>
                      </li>
                    </div>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </aside>
      <aside className='nav-vertical big-sidebar' style={SecondSidebarStyle}>
        <div className='nav-wrapper'>
          <div className='nav-inner-wrapper'>
            <ul>
              {
                SidebarContent.map((menu, key) => {
                  // if (loggedInUserDesignation !== 'ae' && menu.title === 'File upload') {
                  //   return ''
                  // }
                  return (
                    <div
                      key={key}
                      data-bs-toggle='tooltip' data-bs-placement='top' title={menu.disabled}
                      style={{ cursor: menu.disabled ? 'not-allowed' : 'auto' }}
                    >
                      <li>
                        <NavLink disabled={menu.disabled} onClick={(e) => subMenuHandler(e, menu)} to={menu.link}>
                          <img src={menu.icon} alt='sidebar icon' />
                          <span className='roboto font-regular-400'>{menu.title}</span>
                          {menu.hasSubmenu && match !== menu.title && <img className='fa img-fluid' alt='sidebar icon' src={downArrow} />}
                          {menu.hasSubmenu && match === menu.title && <img className='img-fluid fa' alt='sidebar icon' src={upArrow} />}
                        </NavLink>
                        {
                        menu.hasSubmenu && <SubMenu isShowing={match === menu.title} menu={menu.subMenu} />
                      }
                      </li>
                    </div>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
