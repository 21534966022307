import React from 'react'
import styles from './chips.module.scss'

const Chips = ({ text = 'Chips Text', color = 'gold' }) => {
  return (
    <div
      className={styles.container}
      style={{
        background: color
      }}
    >
      <div className={styles.text}>
        {text}
      </div>
    </div>
  )
}

export default Chips
