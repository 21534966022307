import React, { useState, useEffect, useContext } from 'react'
// import { useHistory } from 'react-router-dom'
// import EditIcon from '../../assets/img/edit-selected-icon@2x.png'
import { getMRs } from '../../services/api/mrService'
import { NotifierContext } from '../../context/NotifierContext'
import NoDataFound from '../../components/atoms/NoDataFound'
import { DateToDDMMYYYY } from '../../services/utils/date'
import Paginator from '../../components/molecules/Paginator'
import {
  fetchSectionAndDivisionCode,
  checkIfUserIsAE,
  fetchUserDesignation
} from '../../services/utils/meta'
import { HierarchyContext } from '../../context/HierarchyContext'

export default function List () {
  // const history = useHistory()

  const isUserAE = checkIfUserIsAE()

  const { notifyError } = useContext(NotifierContext)
  const [mrs, setMrs] = useState([])
  const [searchString, setSearchString] = useState('')
  const [loading, setLoading] = useState(true)
  const [pageData, setPageData] = useState({
    lastPage: 0,
    currentPage: 0,
    nextPage: null,
    previousPage: null,
    limit: 20,
    skip: 0
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage] = useState(10)
  const [sectionCode, setSectionCode] = useState('----')
  const [subDivisionCode, setSubDivisionCode] = useState('----')
  // const [trackHierarchyChange, setTrackHierarchyChange] = useState()

  const { selectedHierarchy } = useContext(HierarchyContext)
  const loggedInUserDesignation = fetchUserDesignation()

  useEffect(() => {
    fetchMetaData()
  }, [])

  // useEffect(() => {
  //   setPageNumber(1)
  //   pageNumber !== 1 && setTrackHierarchyChange(selectedHierarchy)
  // }, [selectedHierarchy])

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchMRList()
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [searchString, pageNumber, selectedHierarchy])

  const fetchMRList = async () => {
    try {
      setLoading(true)
      setMrs([])
      const { data } = await getMRs(searchString, pageNumber, perPage, selectedHierarchy)
      setMrs(loggedInUserDesignation === 'ae' ? data.MRList : data.userList)
      setPageData(data.pageData)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchMetaData = async () => {
    try {
      const sectionCode = await fetchSectionAndDivisionCode('sectionCode')
      const subDivisionCode = await fetchSectionAndDivisionCode('subDivisionCode')
      setSectionCode(sectionCode)
      setSubDivisionCode(subDivisionCode)
    } catch (err) {
      notifyError(err)
    }
  }

  // const handleEdit = (mr) => {
  //   try {
  //     const { name: { firstName, lastName }, userInfo: { mrCode }, phoneNumber, userId } = mr
  //     if (mrCode === undefined || mrCode === null) {
  //       throw new Error('Oops! Something went wrong.')
  //     }
  //     history.push({
  //       pathname: `/mr-management/${mrCode}/edit`,
  //       state: {
  //         firstName,
  //         lastName,
  //         mrCode: mrCode ?? '-----',
  //         phoneNumber: phoneNumber?.substring(3),
  //         id: userId
  //       }
  //     })
  //   } catch (err) {
  //     notifyError(err)
  //   }
  // }

  return (
    <>
      <div className='management-content'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md-6'>
              <h1 className='mb-0'>MR Management</h1>
            </div>

          </div>
          <div className={`row ${isUserAE ? '' : 'mt-4'}`}>
            <div className='col-md-12 '>
              <hr className={` "horizontal-line" ${isUserAE ? 'd-block' : 'd-none'}`} />
              <div className={`code-section ${isUserAE ? 'd-flex' : 'd-none'}`}>
                <span>
                  Section Code: <span className='font-medium'>{sectionCode}</span>
                </span>
                <hr className='vertical-line mx-4' />
                <span>
                  Sub Division Code: <span className='font-medium'>{subDivisionCode}</span>
                </span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-management-table list-view-box mt-2'>
                <div className='search-heading pt-0 pb-2'>
                  <div className='search-space position-relative me-auto ms-2'>
                    <input
                      type='text'
                      placeholder='Search by MR Code'
                      value={searchString}
                      onChange={(e) => {
                        setSearchString(e.target.value.trimLeft())
                        setPageNumber(1)
                      }}
                      className='form-control search-field'
                    />
                  </div>
                </div>

                <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th
                          className='pl-15'
                          scope='col'
                        >
                          #
                        </th>
                        <th scope='col'>Name</th>
                        <th scope='col'>MR Code</th>
                        <th scope='col'>MR Phone Number</th>
                        <th scope='col'>Added Date</th>
                        {/* {isUserAE &&
                          (
                            <th
                              scope='col'
                              className='text-right pr-20 }'
                            >
                              Action
                            </th>
                          )
                          } */}
                      </tr>
                    </thead>
                    <tbody>
                      {mrs.map((mr, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className='pl-15'
                              scope='row'
                            >
                              {(pageData.currentPage - 1) * perPage + (index + 1)}
                            </td>
                            <td>{`${mr.name.firstName} ${mr.name.lastName ?? ''}`}</td>
                            <td>{mr.userInfo?.mrCode ?? '-----'}</td>
                            <td>{mr.phoneNumber ?? '----'}</td>
                            <td>{DateToDDMMYYYY(mr.reportsTo?.addedOn)}</td>
                            {/* {isUserAE &&
                              (
                                <td className='text-right pr-20'>
                                  <div
                                    onClick={() => handleEdit(mr)}
                                    role='button'
                                  >
                                    <img
                                      className='img-fluid icon-width-height'
                                      src={EditIcon}
                                      alt='missing icon'
                                    />
                                  </div>
                                </td>
                              )} */}
                          </tr>
                        )
                      })}
                      {mrs.length === 0 && <NoDataFound loading={loading} />}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Paginator
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            onPressNext={() => {
              setPageNumber((prevState) =>
                prevState >= pageData.lastPage ? prevState : prevState + 1
              )
            }}
            onPressPrevious={() => {
              setPageNumber((prevState) => (prevState <= 1 ? prevState : prevState - 1))
            }}
          />
        </div>
      </div>
    </>
  )
}
