import React, { useEffect, useState, useContext } from 'react'
import Modal from '../../atoms/Modal/Modal'
import styles from './sectionWiseResults.module.scss'
import ResultItem from '../FileStatus/ResultItem'
import { getSectionWiseResults } from '../../../services/api/tcAuditService'
import { NotifierContext } from '../../../context/NotifierContext'

const SectionWiseResults = ({
  onClickFailureRows = () => {},
  onClickSkippedRows = () => {},
  visible = false,
  onClose = () => {},
  fileId
}) => {
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialRender, setInitialRender] = useState(true)

  const { notifyError } = useContext(NotifierContext)

  useEffect(() => {
    setInitialRender(false)
    const fetchSectionWiseResults = async () => {
      try {
        setLoading(true)
        const response = await getSectionWiseResults(fileId)
        setResults(response.data)
      } catch (error) {
        notifyError(error)
      } finally {
        setLoading(false)
      }
    }

    // Make API call only when modal opens
    if (!initialRender && visible) {
      fetchSectionWiseResults()
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      title='Section wise Results'
      styles={{
        dialog: { maxWidth: '65%', borderRadius: 5 },
        closeBtn: { filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(119deg) brightness(103%) contrast(102%)' },
        header: { background: styles.primaryColor },
        title: { color: 'white', fontSize: 24, fontWeight: 'bold' }
      }}
      hideFooter
      onModalClose={onClose}
    >
      <div className={`${styles.root}`}>

        {loading
          ? <div>Loading...</div>
          : results.length > 0
            ? results.map((result, index) => {
              const successCount = isNaN(Number(result.count.success)) ? '-' : Number(result.count.success)
              const failureCount = isNaN(Number(result.count.failure)) ? '-' : Number(result.count.failure)
              const skippedCount = isNaN(Number(result.count.skipped)) ? '-' : Number(result.count.skipped)
              const allRowsCount = isNaN(Number(successCount + failureCount + skippedCount)) ? '---' : Number(successCount + failureCount + skippedCount)

              const modifiedMessage = result.message?.split('***') ?? []

              return (
                <React.Fragment key={index}>
                  <div className={styles.heading}>Section Code : {result.sectionCode ?? '-----'}</div>
                  {
                    result.status === 'PROCESSED' &&
                      <div className={styles.resultStatusContainer}>
                        <ResultItem type='all' number={allRowsCount} />
                        <ResultItem type='success' number={successCount} />
                        <ResultItem type='failure' number={failureCount} onClickInfo={onClickFailureRows} />
                        <ResultItem type='skipped' number={skippedCount} onClickInfo={onClickSkippedRows} />
                      </div>
                  }
                  {
                     result.status === 'PROCESSING_FAILED' &&
                       <div className={styles.ineligibleContainer}>
                         <div className={styles.ineligibleHeading}>Section Ineligible</div>
                         <div style={{ marginBottom: 30 }}>
                           <ResultItem type='all' number={allRowsCount} />
                         </div>
                         <div className={styles.ineligibleSubHeading}>Reason :  {modifiedMessage[0] ?? 'There seems to be an error with our system!'}</div>
                         {
                          modifiedMessage.length > 1 &&
                            <div className={styles.ineligibleSubHeading}>
                              Solution :<span className={styles.ineligibleText}>{modifiedMessage[1]} Test solution</span>
                            </div>
                         }

                       </div>
                  }
                </React.Fragment>
              )
            })
            : <div>No Results!</div>}
      </div>
    </Modal>
  )
}

export default SectionWiseResults
