import React from 'react'

const NoDataFound = ({ colSpan, style, message, loading = false }) => {
  return (
    <>
      <tr>
        <td colSpan={colSpan || 12} className='text-center' style={style}>{loading ? 'Loading...' : message || 'No Data Found!'}</td>
      </tr>
    </>
  )
}

export default NoDataFound
