import React, { createContext, useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Auth } from 'aws-amplify'
import { AuthContext } from './AuthContext'
import { useHistory, useLocation } from 'react-router'

export const NotifierContext = createContext()

const NotifierProvider = ({ children }) => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const { pathname } = useLocation()
  const { addToast, removeAllToasts } = useToasts()

  const notifyError = (err) => {
    removeAllToasts()
    // alert(JSON.stringify(err))
    // return

    // Different messages are given during API call and login
    // if (err?.message === "Network Error" || err?.message === "Network error") {
    //     addToast("There seems to be a connectivity issue. Please check your INTERNET CONNECTION and try again", { appearance: 'warning' })
    //     return
    // }

    // Previously handled case
    //  if (err?.message === 'Incorrect username or password.') {
    //    addToast(err?.message, { appearance: 'warning' })
    //    return
    //  }
    if (pathname === '/login') {
      addToast(err?.response?.data?.message ?? err?.message ?? JSON.stringify(err), {
        appearance: 'warning'
      })
    } else {
      Auth.currentSession()
        .then((data) =>
          addToast(`${err?.response?.data?.message ?? err?.message ?? JSON.stringify(err)}`, {
            appearance: 'warning'
          })
        )
        .catch((error) => {
          if (error === 'No current user') {
            window.localStorage.clear()
            authContext.signInSuccessHandler()
            history.push('/')
            addToast('Your session has expired. Please login again.', { appearance: 'warning' })
            return
          }
          addToast(`${error?.response?.data?.message ?? error?.message ?? JSON.stringify(error)}`, {
            appearance: 'warning'
          })
        })
    }
  }

  const notifySuccess = (success) => {
    addToast(success, { appearance: 'success' })
  }

  const notify = (notification) => {
    removeAllToasts()
    addToast(JSON.stringify(notification), { appearance: 'info' })
  }

  return (
    <NotifierContext.Provider
      value={{
        notifyError,
        notifySuccess,
        notify
      }}
    >
      {children}
    </NotifierContext.Provider>
  )
}

export default NotifierProvider
