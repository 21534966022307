import React, { useContext, useEffect, useState } from 'react'
import { Colors } from '../../../theme/colors'
import './hierarchy-modal.scss'
import HierarchyDropdown from '../HierarchyDropdown/HierarchyDropdown'
import { hierarchyDetails } from '../../../assets/offlineData/hierarchyData'
import { fetchUserDesignation } from '../../../services/utils/meta'
import { NotifierContext } from '../../../context/NotifierContext'
import { fetchUserForDesignation } from '../../../services/api/hierarchyService'
import {
  getHierarchyDataByProperty,
  getHierarchyDataOfNextRank,
  getHierarchyDataOfPreviousRank
} from '../../../services/utils/hierarchy'
// import { HierarchyContext } from '../../../context/HierarchyContext'

const HierarchyModal = ({
  onClose = () => {},
  open,
  onSubmit = () => {},
  selectedGlobalHierarchy
}) => {
  const loggedInUserDesignation = fetchUserDesignation()

  const loggedInUserRank = hierarchyDetails.find(
    (user) => user.designation === loggedInUserDesignation
  )?.rank

  // Returns hierarchy data of users ranked below the logged in user
  const filteredHierarchyList = hierarchyDetails.filter(
    (hierarchy) => hierarchy.rank > loggedInUserRank
  )

  const [loading, setLoading] = useState(false)
  const [dropDownsState, setDropDownsState] = useState({
    ce: 'All',
    se: 'All',
    ee: 'All',
    aee: 'All',
    ae: 'All'
  }) // Selected user at every rank
  const [optionsState, setOptionsState] = useState({
    ce: [],
    se: [],
    ee: [],
    aee: [],
    ae: []
  }) // Options of all dropdowns
  const [selectedUser, setSelectedUser] = useState('All') // To store data of the the lowest rank user selected

  const { notifyError } = useContext(NotifierContext)
  // const { setSelectedHierarchy } = useContext(HierarchyContext)

  useEffect(() => {
    fetchUsers(filteredHierarchyList[0]?.designation?.toUpperCase())
  }, [])

  const fetchUsers = async (userType) => {
    try {
      setLoading(true)
      const response = await fetchUserForDesignation(loggedInUserDesignation, userType)
      const tempOptionsState = { ...optionsState }
      tempOptionsState[userType.toLowerCase()] = response.data.userList
      setOptionsState(tempOptionsState)
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleDropdownChange = async (value, designation) => {
    try {
      setLoading(true)
      const currentSelectionRank = getHierarchyDataByProperty('designation', designation).rank

      if (value === 'All') {
        // Disable and reset below dropdowns and its options
        const hierarchyDetailsOfLowerRank = hierarchyDetails.filter(
          (item) => item.rank > currentSelectionRank
        )
        const updatedOptionsState = hierarchyDetailsOfLowerRank.reduce((acc, obj) => {
          acc[obj.designation] = []
          return acc
        }, {})
        const tempOptionsState = { ...optionsState, ...updatedOptionsState }
        setOptionsState(tempOptionsState)

        const updatedDropdownState = hierarchyDetailsOfLowerRank.reduce((acc, obj) => {
          acc[obj.designation] = 'All'
          return acc
        }, {})

        const tempDropdownOptions = {
          ...dropDownsState,
          ...updatedDropdownState,
          ...{ [designation]: 'All' }
        }
        setDropDownsState(tempDropdownOptions)

        // Set higher rank user's data to context and If it is the first dropdown set it to "All"
        const prevRankDesignation = getHierarchyDataOfPreviousRank(currentSelectionRank).designation
        setSelectedUser(
          filteredHierarchyList[0].designation === designation
            ? 'All'
            : dropDownsState[prevRankDesignation]
        )
        return
      }
      if (designation === 'ae') {
        // If user is AE set option to dropdown state
        const userObject = optionsState[designation].find((item) => item._id === value)
        const tempDropdownsState = { ...dropDownsState }
        tempDropdownsState[designation] = userObject
        setDropDownsState(tempDropdownsState)

        // set selected data to context
        setSelectedUser(userObject)
        return
      }

      // Reset the options of below dropdown and make selected option to "All"

      const hierarchyDetailsOfLowerRank = hierarchyDetails.filter(
        (item) => item.rank > currentSelectionRank
      )
      const updatedOptionsState = hierarchyDetailsOfLowerRank.reduce((acc, obj) => {
        acc[obj.designation] = []
        return acc
      }, {})
      const copyOfOptionsState = { ...optionsState, ...updatedOptionsState }
      setOptionsState(copyOfOptionsState)

      const updatedDropdownState = hierarchyDetailsOfLowerRank.reduce((acc, obj) => {
        acc[obj.designation] = 'All'
        return acc
      }, {})

      const userObject = optionsState[designation].find((item) => item._id === value)

      const tempDropdownOptions = {
        ...dropDownsState,
        ...updatedDropdownState,
        ...{ [designation]: userObject }
      }
      setDropDownsState(tempDropdownOptions)
      setSelectedUser(userObject)

      // Data Setup required for API calls and setting options state
      const {
        location: { areaCode }
      } = hierarchyDetails.find((item) => item.designation === designation)
      const designationOfNextRank = getHierarchyDataOfNextRank(currentSelectionRank).designation

      // API Call
      const response = await fetchUserForDesignation(
        loggedInUserDesignation,
        designation.toUpperCase(),
        designationOfNextRank.toUpperCase(),
        userObject.location?.[areaCode] ?? 'err'
      )
      // set response to options state
      const tempOptionsState = { ...copyOfOptionsState }
      tempOptionsState[designationOfNextRank] = response.data.userList
      setOptionsState(tempOptionsState)

      // set selected data to context
    } catch (err) {
      notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
    // handleDropdownChange(
    //   selectedGlobalHierarchy._id,
    //   selectedGlobalHierarchy.userType?.toLowerCase() ?? loggedInUserDesignation
    // )
  }

  return (
    // // <!-- Modal -->
    <div
      style={{ display: open ? 'block' : 'none', background: 'rgba(0,0,0,0.5)', overflowY: 'auto' }}
      className='modal export-excel-modal'
      id='exampleModal'
      tabindex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div
        className='modal-dialog custom-dialog'
        // style={{ maxWidth: '30vw', margin: '10vh auto' }}
        role='document'
      >
        <div className='modal-content'>
          <div
            className='modal-header'
            style={{
              background: Colors.secondaryColor,
              boxShadow: '0px 4px 10.4px 0px rgba(36, 54, 90, 0.25)'
            }}
          >
            <h5
              className='modal-title roboto'
              style={{
                fontSize: '25px',
                fontWeight: 700,
                lineHeight: '32px',
                letterSpacing: '4%',
                color: Colors.white
              }}
              id='exampleModalLabel'
            >
              Display result as
            </h5>
            <i
              className='fa fa-times'
              onClick={onClose}
              style={{
                fontSize: 25,
                color: Colors.white,
                alignSelf: 'flex',
                padding: '2px 5px 2px 5px',
                margin: 0
              }}
              aria-hidden='true'
            />
          </div>
          <div
            className='modal-body'
            style={{ maxHeight: '60vh', overflowY: 'auto', margin: '10px 0px 10px 0px' }}
          >
            {loading &&
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
              >
                <small>
                  Loading...
                </small>
              </div>}
            {filteredHierarchyList.map((hierarchyData, index) => {
              return (
                <HierarchyDropdown
                  key={index}
                  disabled={optionsState[hierarchyData.designation].length === 0}
                  hierarchyName={hierarchyData.area}
                  disabledName={
                    hierarchyDetails.find((item) => item.rank === hierarchyData.rank - 1).area
                  }
                  options={optionsState[hierarchyData.designation]}
                  value={dropDownsState[hierarchyData.designation]?._id ?? 'All'}
                  onChange={({ target: { value } }) => {
                    handleDropdownChange(value, hierarchyData.designation)
                  }}
                >
                  <option
                    className='custom-dropdown-options'
                    value='All'
                  >
                    All
                  </option>
                  {optionsState[hierarchyData.designation].map((option, index) => (
                    <option
                      className='custom-dropdown-options'
                      key={index}
                      value={option._id}
                      selected={
                        dropDownsState[hierarchyData.designation]?._id === option._id || 'All'
                      }
                    >
                      {/* {option.phoneNumber} */}
                      {option.location?.[hierarchyData.location.areaCode] ?? '-----'} -{' '}
                      {option.location?.name?.[hierarchyData.location.areaName] ?? '-----'} -{' '}
                      {option.userType}
                    </option>
                  ))}
                </HierarchyDropdown>
              )
            })}
            {/* {filteredHierarchyList.map((item) => (
              <div>
                {item.designation} - (
                {JSON.stringify(
                  dropDownsState[item.designation]?.location?.[item.location.areaCode] ??
                    dropDownsState[item.designation] ??
                    'err'
                )}
                )
              </div>
            ))} */}
          </div>

          <div className='modal-footer hierarchy-modal-footer'>
            <button
              type='button'
              onClick={handleClose}
              className='btn btn-cancel'
              data-dismiss='modal'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={() => {
                onSubmit(selectedUser)
              }}
              className='btn btn-submit'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HierarchyModal
