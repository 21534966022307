import { API } from 'aws-amplify'

export const fetchUserForDesignation = (
  designation,
  userType,
  secondLevelUserType,
  userLocationCode
) => {
  const queryStringParameters = {
    ...(userType && { userType }),
    ...(secondLevelUserType && { secondLevelUserType }),
    ...(userLocationCode && { userLocationCode })
  }
  return API.get('baseURL', `/${designation}/user`, {
    queryStringParameters
  })
}
