import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import coverImage from '../../assets/img/login.svg'
import { Auth } from 'aws-amplify'
import { NotifierContext } from '../../context/NotifierContext'
import editIcon from '../../assets/img/view-selected-icon@2x.png'
import hidePwd from '../../assets/img/eye-hide.png'
import ChangePwd from '../../components/molecules/ChangePwd'
import { generateOtp } from '../../services/api/authService'

const Login = (props) => {
  const [formFields, setFormFields] = useState({
    section_code: '',
    password: ''
  })
  const [isFormValid, setFormValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [modalVisible, setModalVisibility] = useState(false)
  const [user, setUser] = useState('')

  const notifier = useContext(NotifierContext)
  const history = useHistory()

  useEffect(() => {
    /** Debouncing */
    const timer = setTimeout(() => {
      let checkForm = true
      for (const [key] of Object.entries(formFields)) {
        if (validateField(key) !== '') {
          checkForm = false
        }
      }
      setFormValid(checkForm)
    }, 200)
    return () => {
      clearTimeout(timer)
    }
  }, [formFields])

  const validateField = (name) => {
    let error = ''
    /** Generic test */
    error = formFields[name].length === 0 ? 'Please enter valid value' : ''
    return error
  }

  const handleChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject)
  }

  const submitHandler = async (event) => {
    event.preventDefault()
    if (!isFormValid) return
    try {
      setLoading(true)
      const response = await Auth.signIn(formFields.section_code.trim(), formFields.password.trim())
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        if (response.challengeParam.userAttributes['custom:userGroup'].toLowerCase() === 'mr') {
          throw new Error('You cannot login as MR here! Please use mobile app.')
        }
        setUser(response)
        setLoading(false)
        setModalVisibility(true)
        return
      }
      notifier.notifySuccess('Successfully Logged In')
      response.attributes['custom:userGroup'] =
        response.attributes['custom:userGroup'].toLowerCase()
      if (response.attributes['custom:userGroup'] === 'mr') {
        throw new Error('You cannot login as MR here! Please use mobile app.')
      }
      window.localStorage.setItem('refreshToken', response.signInUserSession.refreshToken.token)
      window.localStorage.setItem('userAttributes', JSON.stringify(response.attributes))
      props.signInSuccess()
      setLoading(false)
    } catch (error) {
      notifier.notifyError(error)
      setLoading(false)
    }
  }

  // Function to handle for password change for first-time users
  const handlePwdChange = async (newPwd) => {
    try {
      setLoading(true)
      const response = await Auth.completeNewPassword(user, newPwd.trim(), [])
      response.challengeParam.userAttributes['custom:userGroup'] =
        response.challengeParam.userAttributes['custom:userGroup'].toLowerCase()
      window.localStorage.setItem('refreshToken', response.signInUserSession.refreshToken.token)
      window.localStorage.setItem('userAttributes', JSON.stringify(response.challengeParam.userAttributes))
      props.signInSuccess()
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const handleForgotPwd = async () => {
    try {
      if (formFields.section_code === '') {
        throw new Error('Username is required to change password')
      }
      const response = await generateOtp(formFields.section_code)
      notifier.notify(response.message)
      history.push(
        `/forgot-password/${formFields.section_code}`
      )
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='unauth-main-container'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-lg-5 pe-0 ps-0'>
              <div className='login-left-image'>
                <img
                  className='img-fluid'
                  src={coverImage}
                  alt='missing icon'
                />
              </div>
            </div>
            <div className='col-lg-7 col-md-12 col-sm-12 pe-0 ps-0'>
              <div className='login-form-detail'>
                <div className='welcome-title text-center mb-5'>
                  <h4>Sign In</h4>
                </div>
                <form
                  action=''
                  method=''
                >
                  {/* <Dropdown
                    options={['DT', 'CE', 'SE', 'EE', 'AEE', 'AE']}
                    ref={dropdownRef}
                  /> */}
                  <div className='mb-3 forms-input'>
                    <i
                      className='fa fa-user'
                      style={{ fontSize: 20 }}
                      aria-hidden='true'
                    />
                    <label className='mb-2'>Username</label>
                    <input
                      type='text'
                      onChange={handleChange}
                      spellcheck='false'
                      autocomplete='off'
                      autocorrect='off'
                      autocapitalize='off'
                      value={formFields.section_code}
                      placeholder='Username'
                      name='section_code'
                      className='form-control'
                    />
                  </div>
                  <div className='mb-3 forms-input'>
                    <i
                      className='fa fa-lock'
                      aria-hidden='true'
                    />
                    <label className='mb-2'>Password</label>
                    <input
                      onChange={handleChange}
                      type={showPassword ? 'text' : 'password'}
                      value={formFields.password}
                      placeholder='Password'
                      name='password'
                      className='form-control'
                    />
                    <img
                      src={showPassword ? editIcon : hidePwd}
                      style={{
                        width: 22,
                        height: 22,
                        position: 'absolute',
                        right: 18,
                        bottom: 15,
                        cursor: 'pointer'
                      }}
                      alt='missing icon'
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  <div className='mb-4 form-check remember-forgotten ps-0'>
                    <div className='form-check me-auto'>
                      {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                      {/* <label className="form-check-label" htmlFor="flexCheckDefault">Remember me</label> */}
                    </div>
                    <div className='forgot'>
                      <Link
                        // to="/forgot-password"
                        onClick={handleForgotPwd}
                      >
                        Forgot / Reset Password
                      </Link>
                    </div>
                  </div>
                  <button
                    type='submit'
                    onClick={submitHandler}
                    disabled={loading}
                    className={`btn btn-primary mb-3 ${isFormValid ? 'custom-active' : ''}`}
                  >
                    LOGIN{'\t'}
                    {loading && (
                      <span
                        className='spinner-grow spinner-grow-sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePwd
        modalVisible={modalVisible}
        setModalVisibility={setModalVisibility}
        loading={loading}
        handlePwdChange={handlePwdChange}
      />
    </>
  )
}

export default Login
