import React, { useEffect, useState } from 'react'
import './checkInternet.scss'
import noWifi from '../../../assets/img/no-wifi.png'

const CheckInternetConnection = ({ children }) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true)

  // On initialization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true)
  })

  window.addEventListener('offline', () => {
    setOnline(false)
  })

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return children
  } else {
    return (
      <div className='container-n'>
        <img
          src={noWifi}
          alt='No internet connection icon'
        />
        <h1 className='heading-n'>No internet connection</h1>
        <p className='body'>
          You are currently offline. Please check your connection and try again.
        </p>
        <div className='buttons'>
          <button
            className='retry-btn'
            onClick={() => {
              window.location.reload()
            }}
          >
            Retry
          </button>
          {/* <button>Go to saved content</button> */}
        </div>
      </div>
    )
  }
}

export default CheckInternetConnection
