import React,
{
// useState

} from 'react'
import { Colors } from '../../../theme/colors'
import './sampleFileModal.scss'
import downloadIcon from '../../../assets/img/file_download-24px.png'
import Spinner from '../Spinner'

const SampleFileModal = ({
  onClose = () => {},
  open,
  okBtnDisabled = false,
  okBtnLoading = false,
  onSubmit = () => {},
  children,
  heading = 'Instructions'
}) => {
  // const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClose()
  }

  return (
    // // <!-- Modal -->
    <div
      style={{ display: open ? 'block' : 'none', background: 'rgba(0,0,0,0.5)', overflowY: 'auto' }}
      className='modal export-excel-modal'
      id='exampleModal'
      tabindex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div
        className='modal-dialog custom-dialog'
        // style={{ maxWidth: '30vw', margin: '10vh auto' }}
        role='document'
      >
        <div className='modal-content'>
          <div
            className='modal-header'
            style={{
              background: Colors.secondaryColor,
              boxShadow: '0px 4px 10.4px 0px rgba(36, 54, 90, 0.25)'
            }}
          >
            <h5
              className='modal-title roboto'
              style={{
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '28px',
                letterSpacing: '4%',
                color: Colors.white,
                fontFamily: 'Roboto, sans-serif'
              }}
              id='exampleModalLabel'
            >
              {heading}
            </h5>
            <i
              className='fa fa-times'
              onClick={onClose}
              style={{
                fontSize: 25,
                color: Colors.white,
                alignSelf: 'flex',
                padding: '2px 5px 2px 5px',
                margin: 0
              }}
              aria-hidden='true'
            />
          </div>
          <div
            className='modal-body'
            style={{ maxHeight: '60vh', overflowY: 'auto', margin: '10px 0px 10px 0px' }}
          >
            {children}
          </div>

          <div className='modal-footer hierarchy-modal-footer'>
            <button
              type='button'
              onClick={handleClose}
              className='btn btn-cancel'
              data-dismiss='modal'
            >
              Close
            </button>
            <button
              type='button'
              disabled={okBtnDisabled}
              onClick={onSubmit}
              className='btn btn-submit'
            >
              Download file
              {'\t'}{'\t'}
              {okBtnDisabled
                ? <Spinner variant='light' />
                : <img className='img-fluid download-icon' src={downloadIcon} alt='missing icon' />}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SampleFileModal
