import { API } from 'aws-amplify'
import { fetchUserAttributes, fetchUserDesignation } from '../utils/meta'
import { getHierarchyDataByProperty } from '../utils/hierarchy'

/// ////////////////////////////////File upload and Audit Functions///////////////////////////
export const getPresignedURL = (fileName, monthYear) => {
  const loggedInUserDesignation = fetchUserDesignation()

  return API.get('baseURL', `/${loggedInUserDesignation}/files/upload`, {
    queryStringParameters: {
      fileCategory: fileName,
      ...((monthYear !== undefined) && { month: monthYear })
    }
  })
}

export const getSampleFilePresignedURL = (fileName) => {
  const loggedInUserDesignation = fetchUserDesignation()

  return API.get('baseURL', `/${loggedInUserDesignation}/files/sample-files/${fileName}`)
}

export const uploadFile = (file, preSignedData) => {
  const { url, fields } = preSignedData

  const formData = new FormData()
  formData.append('bucket', fields.bucket)
  formData.append('key', fields.key)
  formData.append('Content-Type', fields['Content-Type'])
  formData.append('Policy', fields.Policy)
  formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm'])
  formData.append('X-Amz-Credential', fields['X-Amz-Credential'])
  formData.append('X-Amz-Date', fields['X-Amz-Date'])
  formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token'])
  formData.append('X-Amz-Signature', fields['X-Amz-Signature'])
  formData.append('file', file)

  return fetch(url, {
    method: 'POST',
    body: formData
  })
}

export const getUploadStatus = (queryStringParameters) => {
  const loggedInUserDesignation = fetchUserDesignation()
  return API.get('baseURL', `/${loggedInUserDesignation}/files/history`, {
    queryStringParameters
  })
}

// View Success Screen button
export const getDownLoadProcessedResultUrl = (fileCategory, fileId) => {
  const loggedInUserDesignation = fetchUserDesignation()
  return API.get('baseURL', `/${loggedInUserDesignation}/files/download/${fileCategory}/${fileId}`)
}

/// ///////////////////////////////////////////////////Audit Result Functions////////////////////////////////
export const getAuditMetrics = (month, selectedHierarchy) => {
  const loggedInUserDesignation = fetchUserDesignation()

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'cio' || loggedInUserDesignation === 'dt') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/audit-metrics/months/${month}`)
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/audit-metrics/months/${month}`, {
      queryStringParameters: {
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AE makes an API call

  // When no user is selected in hierarchy by CE-AE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/audit-metrics/months/${month}`, {
      queryStringParameters: {
        location: locationName,
        locationCode
      }
    })
  }

  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  return API.get('baseURL', `/${loggedInUserDesignation}/audit-metrics/months/${month}`, {
    queryStringParameters: {
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

export const getAuditResults = (
  month,
  page,
  perPage,
  searchString,
  activeDataTab,
  tcAuditedFilter,
  tcUnAuditedFilter,
  executionType,
  selectedMRs,
  remarks,
  selectedHierarchy
) => {
  const queryStringParameters = {
    month,
    page,
    perPage,
    ...(searchString.length > 0 && { number: searchString }),
    ...(activeDataTab === 'transformers-audited' && { audited: true }),
    ...(activeDataTab === 'transformers-unaudited' && { unaudited: true }),

    ...(tcAuditedFilter === '<-5' && { negativeFiveAndBelow: true }),
    ...(tcAuditedFilter === '-5-0' && { zeroToNegativeFive: true }),
    ...(tcAuditedFilter === '0-5' && { zeroToFive: true }),
    ...(tcAuditedFilter === '5-10' && { fiveToTen: true }),
    ...(tcAuditedFilter === '10-15' && { tenToFifteen: true }),
    ...(tcAuditedFilter === '15-20' && { fifteenToTwenty: true }),
    ...(tcAuditedFilter === '>20' && { twentyAndAbove: true }),

    ...(tcUnAuditedFilter === 'tc-untagged' && { untagged: true }),
    ...(tcUnAuditedFilter === 'tc-without-fr' && { withoutConsumption: true }),
    ...(tcUnAuditedFilter === 'tc-with-remarks' && { withRemarks: true }),

    ...(executionType.length > 0 && { executionType }),
    ...(selectedMRs.length > 0 && { mrCode: selectedMRs }),
    ...(remarks.length > 0 && { remark: remarks }),
    manipulated: true
  }

  const loggedInUserDesignation = fetchUserDesignation()

  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', '/ae/transformer-centres', {
      queryStringParameters
    })
  }

  // When CIO / DT makes an API call
  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // If no user in hierarchy is selected by DT/CIO
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
        queryStringParameters: {
          ...queryStringParameters
        }
      })
    }

    // When a user in hierarchy is selected by DT/CIO
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode]
      }
    })
  }

  // When CE-AEE makes an API call

  // When no user is selected in hierarchy by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
      queryStringParameters: {
        ...queryStringParameters,
        location: locationName,
        locationCode
      }
    })
  }

  // When a user in hierarchy is selected by CE-AEE
  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  return API.get('baseURL', `/${loggedInUserDesignation}/transformer-centres`, {
    queryStringParameters: {
      ...queryStringParameters,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode]
    }
  })
}

export const auditIndividualTC = (transformerId, month) => {
  return API.post('baseURL', `/ae/audit/transformer-centres/${transformerId}/months/${month}`)
}

export const auditTCs = (month) => {
  return API.post('baseURL', `/ae/audit/transformer-centres/months/${month}`)
}

export const getSectionWiseResults = (fileId) => {
  return API.get('baseURL', `/cio/files/section-wise-results/${fileId}`)
}
