const Ajv = require('ajv').default
const ajv = new Ajv({ allErrors: true })
require('ajv-errors')(ajv, { singleError: true }
)
require('ajv-keywords')(ajv, ['transform'])

export const updateMRValidation = (formFields, prevData) => {
  // Properties should not be added if previous page properties are same.
  const dataFormatter = {
    ...((prevData.firstName !== formFields.firstName || prevData.lastName !== formFields.lastName) && {
      name: {
        firstName: formFields.firstName,
        lastName: formFields.lastName
      }
    }),
    ...(prevData.phoneNumber !== formFields.phone && {
      phoneNumber: `+91${formFields.phone}`
    })
  }
  const schema = {
    title: 'CreateMR',
    description: 'Create meter-reader user object',
    type: 'object',
    additionalProperties: false,
    properties: {
      name: {
        type: 'object',
        required: ['firstName'],

        additionalProperties: false,
        properties: {
          firstName: {
            type: 'string',
            pattern: '^[a-zA-Z .]{3,30}$',
            transform: ['trim'],
            errorMessage: 'Enter a valid First name'

          },
          lastName: {
            type: 'string',
            pattern: '^[a-zA-Z .]*$',
            transform: ['trim'],
            errorMessage: 'Enter a valid Last name'
          }
        }
      },
      mrCode: {
        type: 'string',
        minLength: 6,
        maxLength: 10,
        pattern: '^[0-9]*$',
        errorMessage: 'Enter a valid MR code'
      },
      phoneNumber: {
        type: 'string',
        minLength: 13,
        maxLength: 13,
        pattern: '^\\+91[6789][0-9]{9}$',
        errorMessage: 'Enter a valid 10 digit phone number'
      }

    },
    errorMessage: {
      required: 'Missing mandatory properties',
      additionalProperties: 'No additional properties allowed',
      properties: {
        firstName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
        lastName:
                    'FirstName should only contain alphabets and have a minimum length of 3',
        phoneNumber: 'Enter a valid Indian phone number',
        mrCode: 'Invalid MR Code',
        gender: 'Invalid gender',
        email: 'Invalid email',
        line1: 'Invalid data in address line1 ',
        line2: 'Invalid data in address line2 ',
        city: 'Invalid data in address city ',
        state: 'Invalid data in address state ',
        pinCode: 'Invalid data in pin Code '
      }
    }
  }

  const validation = ajv.compile(schema)
  const isValid = validation(dataFormatter)
  if (isValid) return dataFormatter
  throw (validation.errors[0])
}
