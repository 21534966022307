import React, { useEffect, useState } from 'react'

const Paginator = ({ currentPage = 0, lastPage = 0, onPressNext = () => { }, onPressPrevious = () => { } }) => {
  const [showPrevious, setShowPrevious] = useState(false)
  const [showNext, setShowNext] = useState(false)

  // console.log(currentPage == 1)

  useEffect(() => {
    currentPage <= 1 ? setShowPrevious(false) : setShowPrevious(true)
    currentPage === lastPage ? setShowNext(false) : setShowNext(true)
  }, [currentPage, lastPage])

  const previousClickHandler = () => {
    if (currentPage === 1) return
    onPressPrevious()
  }

  const nextClickHandler = () => {
    if (currentPage === lastPage) return
    onPressNext()
  }

  return (
    <div className='row mt-4'>
      <div className='col-md-12'>
        <nav aria-label='Page navigation example'>
          <ul className='pagination mb-0'>
            <li className='page-item previous-item'>
              {showPrevious &&
                <a
                  style={{ cursor: 'pointer' }}
                  className='page-link border-0 active'
                  onClick={previousClickHandler}
                >
                  Previous
                </a>}
            </li>

            <li className='page-item'><a className='page-link'>{currentPage}</a></li>

            <li className='page-item previous-item'><span>Out of {lastPage}</span>
              {showNext &&
                <a
                  style={{ cursor: 'pointer' }}
                  className='page-link border-0 active'
                  onClick={nextClickHandler}
                >
                  Next
                </a>}
            </li>

          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Paginator
