import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { validateProfileDetails } from '../../services/validators/profileSchema'
import { NotifierContext } from '../../context/NotifierContext'
import { fetchAEProfileDetails, updateProfileDetails } from '../../services/api/profileService'
import FullScreenLoader from '../../components/atoms/FullScreenLoader'

export default function ProfileEdit (props) {
  const [firstFormState, setFirstFormState] = useState({ modalVisible: true })
  const [secondFormState, setSecondFormState] = useState({ modalVisible: false })
  const [isFormValid] = useState(true)

  const notifier = useContext(NotifierContext)

  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    // gender: '',
    sectionName: '',
    sectionCode: '',
    designation: '',
    contactNumber: ''
  })

  const [initialFields, setInitialFields] = useState({
    firstName: '',
    lastName: '',
    // gender: '',
    sectionName: '',
    sectionCode: '',
    designation: '',
    contactNumber: ''
  })
  const [loading, setLoading] = useState(false)

  const [onSubmit, setOnSubmit] = useState(false)
  const history = useHistory()

  useEffect(() => {
    fetchAEProfile()
  }, [])

  const fetchAEProfile = async () => {
    try {
      setLoading(true)
      const response = await fetchAEProfileDetails()
      const { name, location, phoneNumber, userType } = response?.data
      setFormFields({
        ...formFields,
        firstName: name?.firstName,
        lastName: name?.lastName,
        // gender,
        sectionName: location.name?.sectionName,
        sectionCode: location.sectionCode,
        designation: userType,
        contactNumber: phoneNumber ? phoneNumber.substring(3) : ''
      })
      setInitialFields({
        ...formFields,
        firstName: name?.firstName,
        lastName: name?.lastName,
        // gender,
        sectionName: location.name?.sectionName,
        sectionCode: location.sectionCode,
        designation: userType,
        contactNumber: phoneNumber ? phoneNumber.substring(3) : ''
      })
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event, key) => {
    event.preventDefault()
    setFormFields({ ...formFields, firstName: event.target.value })
    const formFieldsObject = {
      ...formFields,
      [key]: event.target.value
    }
    setFormFields(formFieldsObject)
  }

  const onSubmitClick = (event) => {
    event.preventDefault()
    try {
      const validationResult = validateProfileDetails(formFields, initialFields)
      if (Object.keys(validationResult).length === 0) throw new Error("You haven't changed any fields.")

      setFirstFormState({ modalVisible: false })
      setSecondFormState({ modalVisible: true })
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  const onConfirmClick = async () => {
    setOnSubmit(true)
    try {
      const validationResult = validateProfileDetails(formFields, initialFields)
      await updateProfileDetails(validationResult)
      notifier.notifySuccess('Successfully updated AE Profile')
      history.push('/profile')
    } catch (err) {
      notifier.notifyError(err)
      setOnSubmit(false)
    }
  }

  const backHandleClick = (event) => {
    event.preventDefault()
    setFirstFormState({ modalVisible: true })
    setSecondFormState({ modalVisible: false })
  }

  return (
    <>
      {firstFormState.modalVisible === true
        ? (
          <div className='profile-content'>
            {loading
              ? (
                <FullScreenLoader />
                )
              : (
                <div className='container'>
                  <div className='row align-items-center'>
                    <div className='col-12'>
                      <h1 className='mb-3'>My Profile</h1>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='list-view-box mt-2'>
                        <h3 className='mb-4'>Personal Details</h3>
                        <div className='profile-form'>
                          <form>
                            <div className='forms-input'>
                              <div className='row'>
                                <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>
                                    First Name<sup className='red'>*</sup>
                                  </label>
                                  <input
                                    name='FirstName'
                                    onChange={(e) => handleChange(e, 'firstName')}
                                    value={formFields.firstName}
                                    type='text'
                                    placeholder='First Name'
                                    className='form-control'
                                  />
                                </div>
                                <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>
                                    Last Name<sup className='red' />
                                  </label>
                                  <input
                                    name='LastName'
                                    onChange={(e) => handleChange(e, 'lastName')}
                                    value={formFields.lastName}
                                    type='text'
                                    placeholder='Last Name'
                                    className='form-control'
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                {/* <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>
                                    Gender<sup className='red'>*</sup>
                                  </label>
                                  <div className='row'>
                                    <div
                                      onClick={() => setFormFields({ ...formFields, gender: 'male' })}
                                      className='col-12 col-md-5'
                                    >
                                      <input
                                        name='male'
                                        checked={formFields.gender === 'male'}
                                        className='form-check-input'
                                        type='radio'
                                        id='male'
                                      />
                                      {' '}
                                      <label
                                        className='form-check-label'
                                        htmlFor='male'
                                      >
                                        Male
                                      </label>
                                    </div>
                                    <div
                                      onClick={() => setFormFields({ ...formFields, gender: 'female' })}
                                      className='col-12 col-md-5'
                                    >
                                      <input
                                        name='female'
                                        checked={formFields.gender === 'female'}
                                        className='form-check-input'
                                        type='radio'
                                        id='female'
                                      />
                                      {' '}
                                      <label
                                        className='form-check-label'
                                        htmlFor='female'
                                      >
                                        Female
                                      </label>
                                    </div>
                                    <div
                                      onClick={() => setFormFields({ ...formFields, gender: 'others' })}
                                      className='col-12 col-md-5'
                                    >
                                      <input
                                        name='others'
                                        checked={formFields.gender === 'others'}
                                        className='form-check-input'
                                        type='radio'
                                        id='female'
                                      />
                                      {' '}
                                      <label
                                        className='form-check-label'
                                        htmlFor='others'
                                      >
                                        Other
                                      </label>
                                    </div>
                                  </div>
                                </div> */}
                                <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>Section Name</label>
                                  <input
                                    name='SectionName'
                                    disabled
                                    value={formFields.sectionName}
                                    type='text'
                                    placeholder='Section Name'
                                    className='form-control'
                                  />
                                </div>
                                <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>Section Code</label>
                                  <input
                                    name='SectionCode'
                                    disabled
                                    value={formFields.sectionCode}
                                    type='text'
                                    placeholder='Section Code'
                                    className='form-control'
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>Designation</label>
                                  <input
                                    name='designation'
                                    disabled
                                    value={formFields.designation}
                                    type='text'
                                    placeholder='Designation'
                                    className='form-control'
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-12 col-md-4 mb-4'>
                                  <label className='form-label font-regular-400'>Contact Number</label>
                                  <input
                                    name='ContactNumber'
                                    type='text'
                                    pattern='[0-9]*'
                                    maxLength='10'
                                    onChange={(e) => handleChange(e, 'contactNumber')}
                                    value={formFields.contactNumber}
                                    placeholder='Contact Number'
                                    className='form-control'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12 mt-3 text-end tc-submit-btn'>
                                <button
                                  onClick={onSubmitClick}
                                  type='submit'
                                  className={`btn btn-primary profile-active-btn ${
                                isFormValid ? '' : 'btn-grey'
                              }`}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
          </div>
          )
        : null}
      {secondFormState.modalVisible === true
        ? (
          <div className='profile-content'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-12'>
                  <h1 className='mb-3'>My Profile</h1>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='list-view-box mt-2'>
                    <h3 className='mb-4'>Personal Details</h3>
                    <div className='profile-form'>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>First Name</label>
                          <p className='label-value'>{formFields.firstName}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Last Name</label>
                          <p className='label-value'>{formFields.lastName || '----'}</p>
                        </div>
                      </div>
                      {/* <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Gender</label>
                          <p className='label-value'>{formFields.gender}</p>
                        </div>
                      </div> */}
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Section Name</label>
                          <p className='label-value'>{formFields.sectionName}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Section Code</label>
                          <p className='label-value'>{formFields.sectionCode}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Designation</label>
                          <p className='label-value'>{formFields.designation}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-4 mb-4'>
                          <label className='form-label font-medium'>Contact Number</label>
                          <p className='label-value'>+91 {formFields.contactNumber}</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12 mt-3 text-end tc-submit-btn'>
                          <button
                            onClick={backHandleClick}
                            type='submit'
                            className='btn btn-primary edit-btn'
                          >
                            Edit
                          </button>
                        &nbsp;
                          <button
                            onClick={onConfirmClick}
                            disabled={onSubmit}
                            type='submit'
                            className={`btn btn-primary profile-active-btn ${
                            isFormValid ? '' : 'btn-grey'
                          }`}
                          >
                            Confirm
                          </button>
                        &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        : null}
    </>
  )
}
