import React from 'react'
import styles from './loadMoreButton.module.scss'
import arrowIcon from '../../../assets/img/back-arrow.png'

const LoadMoreButton = ({ onClick, loading }) => {
  return (
    <div className={styles.button} onClick={onClick}>
      {loading
        ? (
          <>Loading...
            <span
              className={`spinner-border spinner-border-sm ${styles.spinner}`}
              role='status'
              aria-hidden='true'
            />
          </>
          )
        : (
          <>
            Load More
            <img src={arrowIcon} alt='arrow down' className={styles.arrowDown} />
          </>
          )}
    </div>
  )
}

export default LoadMoreButton
