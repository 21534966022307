import React from 'react'
import styles from './auditMonthStickyHeader.module.scss'

const AuditMonthStickyHeader = ({ monthYear = 'MMMM YYYY' }) => {
  return (
    <div className={styles.auditMonthHeading}>
      <div className={styles.hr} />
      <div className={styles.text}>Audit Month : {monthYear}</div>
      <div className={styles.hr} />
    </div>
  )
}

export default AuditMonthStickyHeader
