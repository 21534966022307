import { API } from 'aws-amplify'
import { fetchUserAttributes, fetchUserDesignation } from '../utils/meta'
// import { hierarchyDetails } from '../../assets/offlineData/hierarchyData'
import { getHierarchyDataByProperty } from '../utils/hierarchy'

/// ///////////////////////////////////////////////////////Feeder APIS////////////////////////////////////////////////
export const getFeeders = (pageNumber, selectedHierarchy, mdmCode, selectedFilter) => {
  const queryParameters = {
    page: pageNumber,
    perPage: 20,
    ...(mdmCode.length > 0 && { mdmCode })

  }

  const loggedInUserDesignation = fetchUserDesignation()

  if (loggedInUserDesignation === 'ae') {
    return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
      queryStringParameters: {
        ...(selectedFilter !== '' && { tagged: selectedFilter === 'tagged' ? true : '' }),
        ...queryParameters
      }
    })
  }

  if (loggedInUserDesignation === 'dt' || loggedInUserDesignation === 'cio') {
    // When no user in hierarchy is selected by CIO/DT
    if (selectedHierarchy === 'All') {
      return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
        queryStringParameters: {
          ...queryParameters
        }
      })
    }

    // When a user in hierarchy is selected by CIO/DT
    const {
      location: { areaCode, name: locationName }
    } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

    return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
      queryStringParameters: {
        tagged: true,
        location: locationName,
        locationCode: selectedHierarchy.location[areaCode],
        ...queryParameters
      }
    })
  }

  // Request made by any user CE-AEE

  // When no user in hierarchy is selected by CE-AEE
  if (selectedHierarchy === 'All') {
    const {
      areaCode: cognitoAreaCode,
      location: { name: locationName }
    } = getHierarchyDataByProperty('designation', loggedInUserDesignation)
    const { [`custom:${cognitoAreaCode}`]: locationCode } = fetchUserAttributes()

    // When a user in hierarchy is selected by CE-AEE
    return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
      queryStringParameters: {
        tagged: true,
        location: locationName,
        locationCode,
        ...queryParameters
      }
    })
  }

  const {
    location: { areaCode, name: locationName }
  } = getHierarchyDataByProperty('designation', selectedHierarchy.userType.toLowerCase())

  // Return this when any hierarchy below the user is selected
  return API.get('baseURL', `/${loggedInUserDesignation}/feeders`, {
    queryStringParameters: {
      tagged: true,
      location: locationName,
      locationCode: selectedHierarchy.location[areaCode],
      ...queryParameters
    }
  })
}

export const searchFeeders = (mdmCode, stationCode) => {
  return API.get('baseURL', '/ae/feeders', {
    queryStringParameters: {
      page: 1,
      perPage: 100,
      mdmCode,
      stationCode
    }
  })
}

export const tagAEToFeeder = (stationCode, mdmCode) => {
  return API.patch('baseURL', `/ae/power-stations/${stationCode}/feeders/${mdmCode}/tag`)
}
