import React from 'react'
import './hierarchy-dropdown.scss'
import chevron from '../../../assets/img/chevron.svg'

const HierarchyDropdown = ({
  disabled = false,
  disabledName,
  hierarchyName = 'an option',
  options = ['Options not set'],
  value = 'All',
  children,
  onChange = () => {}
}) => {
  return (
    <div className='mb-4 hierarchy-dropdown-container'>
      <div className='dropdown-sub-container'>
        <label className={`mb-1 dropdown-label ${disabled ? 'disabled' : ''}`}>
          Select {hierarchyName}
        </label>
        {disabled && (
          <img
            src={chevron}
            className='custom-disabled-arrow'
            alt='Custom Arrow'
          />
        )}
        <select
          value={value}
          onChange={onChange}
          disabled={disabled}
          className='form-select custom-dropdown'
          aria-label='Large select example'
        >
          {children}
          {/* <option value="1">CE-1</option>
        <option value="2">CE-2</option>
        <option value="3">CE-3</option> */}
        </select>
      </div>
      {disabled && (
        <small className='disabled'>Select a {disabledName} to enable this option.</small>
      )}
    </div>
  )
}

export default HierarchyDropdown
