import React from 'react'

const Spinner = ({ variant = 'dark' }) => {
  return (
    <div className={`spinner-border text-${variant} spinner-border-sm`} role='status'>
      {/* <span className="sr-only">Loading...</span> */}
    </div>
  )
}

export default Spinner
