import React from 'react'

const Button = ({ onClick, className = 'btn btn-primary', disabled, children, style }) => {
  return (
    <button
      type='submit'
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  )
}

export default Button
