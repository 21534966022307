import React, { Fragment } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import AuditResult from './AuditResult'
import './auditResult.scss'

export default function AuditResultContainer () {
  return (
    <>
      <div className='tc-audit-file-management-content-local'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='comman-head'>
                <div className='management-title'>
                  <h1>TC Audit</h1>
                </div>

              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='tc-audit-file-management-list'>

                <div className='tc-audit-file-list audit-result-list mb-3'>

                  <div
                    className='collapse collapse-detail show'
                    id='collapseAuditFile-2'
                  >
                    <AuditResult />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
