import React from 'react'

const EnvironmentStrip = ({ children }) => {
  return (
    <div
      style={{ }} // scroll-fix (prev change - paddingBottom :45px)
    >
      {children}
      {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            background: 'gold',
            width: '100%',
            opacity: 1,
            fontWeight: 'bold',
            fontStyle: 'italic',
            textTransform: 'uppercase',
            zIndex: 100000
          }}
        >
          {/* {process.env.REACT_APP_ENVIRONMENT !== "STAGING" && process.env.REACT_APP_ENVIRONMENT !== "DEV" ? "PLEASE SET ENV VARIABLES" : process.env.REACT_APP_ENVIRONMENT} */}
          {process.env?.REACT_APP_ENVIRONMENT ?? 'PLEASE SET ENV VARIABLES'}
        </div>
      )}
    </div>
  )
}

export default EnvironmentStrip
