import { API } from 'aws-amplify'

export const generateOtp = (username) => {
  return API.post('baseURL', `/generate-otp/${username}`, {
    body: {
      otpType: 'forgotPassword'
    }
  })
}

export const resetPwd = (username, otp, newPassword) => {
  return API.post('baseURL', `/reset-password/${username}`, {
    body: {
      otp,
      newPassword
    }
  })
}
