import React, { useEffect, useState, useContext } from 'react'
import filterIcon from '../../../assets/img/filter.png'
import Filter from '../../Shared/Filter/Filter'
import 'react-datepicker/dist/react-datepicker.css'
import BillingTabSearch from '../Shared/BillingTabSearch'
import TagTCModal from '../../Shared/TagTCModal'
import { getInstallations, getTCs, tagInstallation } from '../../../services/api/billingService'
import { AuditMonthContext } from '../../../context/AuditMonthContext'
import { NotifierContext } from '../../../context/NotifierContext'
import NoDataFound from '../../../components/atoms/NoDataFound'
import Paginator from '../../../components/molecules/Paginator'
import Ellipsis from '../../../components/molecules/Ellipsis'
import { perPageCount } from '../../../assets/offlineData/apiCallDetails'
import { installationsTariffs, durations } from '../../../assets/offlineData/FilterOptions'
import { Colors } from '../../../theme/colors'
import { clearTime } from '../../../services/utils/setTimeOut'
import { checkIfUserIsAE } from '../../../services/utils/meta'
import { HierarchyContext } from '../../../context/HierarchyContext'

const Table = ({ onTagTC }) => {
  const isUserAE = checkIfUserIsAE()

  const [searchRRNumber, setSearchRRNumber] = useState('')
  const [loading, setLoading] = useState(true)

  /// ///////////////////////////////////Tag TC Modal
  const [modalVisible, setModalVisibility] = useState(false)
  const [selectedTC, setSelectedTC] = useState([])
  const [selectedInstallation, setSelectedInstallation] = useState([])
  const [loadingTc, setLoadingTc] = useState(false)
  const [TCs, setTCs] = useState([])
  const [tagTcBtnDisabled, setTagTcBtnDisabled] = useState(false)

  /// /////////////////////////////////////////////Filter States///////////////////////////////////////////
  const [selectedMR, setSelectedMR] = useState('')
  const [selectedReadingDay, setSelectedReadingDay] = useState('')

  const [duration, setDuration] = useState([])
  const [tariff, setTariff] = useState([])
  const [filterVisible, setFilterVisible] = useState(false)
  /// /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [installationsCount, setInstallationsCount] = useState('')
  const [installationData, setInstallationData] = useState([])
  const [pageData, setPageData] = useState({
    currentPage: 0,
    nextPage: 1,
    previousPage: 1,
    lastPage: 0
  })

  const { auditMonthYear, mrCode } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext)
  const { selectedHierarchy } = useContext(HierarchyContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchNewInstallations(1)
    }, clearTime)
    return () => {
      clearTimeout(timer)
    }
  }, [searchRRNumber, auditMonthYear, selectedHierarchy])

  const fetchNewInstallations = async (pageNumber) => {
    try {
      setLoading(true)
      const response = await getInstallations(
        auditMonthYear,
        pageNumber,
        true,
        searchRRNumber,
        selectedReadingDay,
        selectedMR,
        tariff,
        [],
        duration,
        selectedHierarchy
      )
      setInstallationData(response.data.installations)
      setInstallationsCount(response.data.count)
      setPageData({
        currentPage: response.data.pageData.currentPage,
        nextPage: response.data.pageData.nextPage,
        previousPage: response.data.pageData.previousPage,
        lastPage: response.data.pageData.lastPage
      })
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const searchByMrCodeAndReadingDay = async () => {
    if (!selectedMR && !selectedReadingDay) {
      notifier.notifyError('Please select MR code or Reading day')
      return
    }
    fetchNewInstallations(1)
  }

  const applyFilters = async () => {
    fetchNewInstallations(1)
    setFilterVisible(false)
  }

  const resetFilter = async () => {
    try {
      setTariff([])
      setDuration([])
      setLoading(true)
      const response = await getInstallations(
        auditMonthYear,
        1,
        true,
        searchRRNumber,
        selectedReadingDay,
        selectedMR,
        [],
        [],
        [],
        selectedHierarchy
      )
      setInstallationData(response.data.installations)
      setInstallationsCount(response.data.count)
      setPageData({
        currentPage: response.data.pageData.currentPage,
        nextPage: response.data.pageData.nextPage,
        previousPage: response.data.pageData.previousPage,
        lastPage: response.data.pageData.lastPage
      })
      setLoading(false)
      setFilterVisible(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const onClearMrCodeAndReadingDay = async () => {
    try {
      setSelectedMR('')
      setSelectedReadingDay('')
      setLoading(true)
      const response = await getInstallations(
        auditMonthYear,
        1,
        true,
        searchRRNumber,
        '',
        '',
        tariff,
        [],
        duration,
        selectedHierarchy
      )
      setInstallationData(response.data.installations)
      setInstallationsCount(response.data.count)
      setPageData({
        currentPage: response.data.pageData.currentPage,
        nextPage: response.data.pageData.nextPage,
        previousPage: response.data.pageData.previousPage,
        lastPage: response.data.pageData.lastPage
      })
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const fetchTCByNumber = async (readingDay, MRCode) => {
    setLoadingTc(true)
    try {
      const response = await getTCs(readingDay, 1, 100, auditMonthYear, '', MRCode)
      setTCs(response.data.transformers)
      setLoadingTc(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoadingTc(false)
    }
  }

  const tcTag = async () => {
    setTagTcBtnDisabled(true)

    if (selectedTC.length <= 0) {
      notifier.notifyError('Please select tc to tag')
      return
    }

    try {
      await tagInstallation(selectedTC.id, selectedInstallation.id, auditMonthYear)
      notifier.notifySuccess('Successfully Tagged TC')
      onTagTC()
      setModalVisibility(false)
      fetchNewInstallations(1)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setTagTcBtnDisabled(false)
    }
  }

  const filterData = [
    {
      heading: 'Duration',
      shape: 'square',
      options: [...durations],
      value: duration,
      setValue: (selectedValue) => {
        duration.includes(selectedValue)
          ? setDuration(duration.filter((item) => item !== selectedValue))
          : setDuration([selectedValue])
      }
    },
    {
      heading: 'Tariff',
      shape: 'square',
      options: [...installationsTariffs],
      value: tariff,
      setValue: (selectedValue) => {
        tariff.includes(selectedValue)
          ? setTariff(tariff.filter((item) => item !== selectedValue))
          : setTariff([...tariff, selectedValue])
      }
    }
  ]

  const filterHandler = (event) => {
    event.preventDefault()
    setFilterVisible(!filterVisible)
  }

  const modalHandler = (event) => {
    event.preventDefault()
    setModalVisibility(!modalVisible)
  }

  return (
    <>
      <Filter
        filterData={filterData}
        disableApply={!(tariff.length >= 1 || duration.length >= 1)}
        isFilterVisible={filterVisible}
        onFilterClose={filterHandler}
        onFiltersApply={() => applyFilters()}
        onResetApply={resetFilter}
      />
      <TagTCModal
        btnDisable={tagTcBtnDisabled}
        options={TCs}
        loadingTc={loadingTc}
        selectedTC={selectedTC}
        onSelectTC={setSelectedTC}
        isModalVisible={modalVisible}
        onModalClose={(event) => {
          modalHandler(event)
        }}
        onPressOK={() => tcTag()}
      />
      {/* <Modal title={modalObject.title} type={modalObject.type} isModalVisible={modalObject.modalVisible} onModalClose={modalHandler} /> */}
      <div className='col-md-9 mb-5'>
        <div className='row mt-35'>
          <div className='col-md-12'>
            <div className='comman-head'>
              <div className='installation-title'>
                <h1>Untagged Installations</h1>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='main-tc-name-form mb-3'>
              {isUserAE &&
                <BillingTabSearch
                  MRs={mrCode}
                  selectedMR={selectedMR}
                  showClearButton={!!(selectedMR || selectedReadingDay)}
                  onClear={onClearMrCodeAndReadingDay}
                  setSelectedMR={setSelectedMR}
                  selectedReadingDay={selectedReadingDay}
                  setSelectedReadingDay={setSelectedReadingDay}
                  onSearch={() => searchByMrCodeAndReadingDay()}
                />}

              <div className='row'>
                <div className='col-md-12'>
                  <div className='list-view-box'>
                    <div className='search-heading'>
                      <div className='digit-installation-title mb-3'>
                        <h3 className='mb-4 ms-0 font-medium-500'>
                          {installationsCount?.toLocaleString('en-IN')} Installations
                        </h3>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-6'>
                          <div className='search-space'>
                            <input
                              type='text'
                              maxLength='25'
                              placeholder='RR Number'
                              value={searchRRNumber}
                              onChange={(e) => {
                                setSearchRRNumber(e.target.value)
                              }}
                              className='form-control search'
                            />
                          </div>
                        </div>
                        <div className='col-12 col-md-6 text-right'>
                          <div className='export-filter'>
                            {/* <a className="export-btn">
                              <img
                                className="fa img-fluid"
                                src={excelIcon}
                              />
                              &nbsp; <span>Export to Excel</span>
                            </a> */}
                            <button
                              onClick={filterHandler}
                              type='button'
                              className='btn btn-primary filter-btn'
                            >
                              <img
                                src={filterIcon}
                                alt='missing icon'
                                className=''
                                width='18'
                                height='12'
                              />
                              Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th
                              className='pl-15'
                              scope='col'
                            >
                              #
                            </th>
                            <th scope='col'>MR Code</th>
                            <th scope='col'>Reading Day</th>
                            <th scope='col'>Account ID</th>
                            <th scope='col'>RR Number</th>
                            <th scope='col'>Tariff</th>
                            <th scope='col'>Consumer Name</th>
                            <th scope='col'>Sanction Load</th>
                            <th scope='col'>TC Number</th>
                          </tr>
                        </thead>

                        <tbody>
                          {loading
                            ? (
                              <NoDataFound loading={loading} />
                              )
                            : (
                                installationData.length === 0 && (
                                  <NoDataFound message='No Installations Found!' />
                                )
                              )}
                          {!loading &&
                            installationData.map((installation, index) => (
                              <tr key={index}>
                                <td
                                  className='pl-15'
                                  scope='row'
                                >
                                  {(pageData.currentPage - 1) * perPageCount + (index + 1)}
                                </td>
                                <td>{installation.MR?.mrCode ?? '----'}</td>
                                <td>{installation.readingDay || '----'}</td>
                                <td>{installation.accountId ?? '----'}</td>
                                <td>{installation.RRNumber ?? '----'}</td>
                                <td>{installation.tariff?.short ?? '----'}</td>
                                <Ellipsis>
                                  {'consumerName' in installation ? installation.consumerName : '-'}
                                </Ellipsis>
                                <td>
                                  {installation.sanctionedLoad.kW} KW,{' '}
                                  {installation?.sanctionedLoad?.hp ?? '--'} HP
                                </td>
                                <td>
                                  {isUserAE
                                    ? (
                                      <a
                                        onClick={(event) => {
                                          event.preventDefault()
                                          fetchTCByNumber(
                                            installation?.readingDay,
                                            installation.MR?.mrCode
                                          )
                                          setModalVisibility(true)
                                          setSelectedTC([])
                                          setSelectedInstallation(installation)
                                        }}
                                    // href="#"
                                        className='tc-number'
                                        style={{
                                          backgroundColor: Colors.secondaryColor,
                                          cursor: isUserAE ? 'pointer' : 'auto'
                                        }}
                                      >
                                        <span className='active'>Tag-TC</span>
                                      </a>
                                      )
                                    : <div className=''>Untagged TC</div>}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav
          className='float-end'
          aria-label='Page navigation example'
        >
          <Paginator
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            onPressNext={() => {
              fetchNewInstallations(pageData.currentPage + 1)
            }}
            onPressPrevious={() => {
              fetchNewInstallations(pageData.currentPage - 1)
            }}
          />
        </nav>
      </div>
    </>
  )
}

export default Table
