import React from 'react'
// import closeIcon from '../../assets/img/close-icon1.svg'

const Filter = ({
  isFilterVisible,
  onFilterClose,
  filterData,
  onResetApply,
  onFiltersApply,
  customUI
}) => {
  return (
    <div
      style={{
        display: isFilterVisible ? 'block' : 'none',
        background: 'rgba(0,0,0,0.5)',
        overflowY: 'auto'
      }}
      className='modal export-excel-modal'
      id='exampleModal'
      tabindex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div
        className='modal-dialog'
      // style={{ maxWidth: '30vw', margin: '10vh auto' }}
        role='document'
      >
        <div
          className='modal-content'
          style={{
            height: '100vh',
            position: 'fixed',
            right: 0,
            bottom: 0,
            width: '40%',
            borderRadius: 0
          }}
        >
          <div
            className='modal-header'
            style={{
              background: 'rgba(36, 54, 90)',
              boxShadow: '0px 4px 10.4px 0px rgba(36, 54, 90, 0.25)',
              borderRadius: 0
            }}
          >
            <h5
              className='modal-title roboto'
              style={{
                fontSize: '25px',
                fontWeight: 700,
                lineHeight: '32px',
                letterSpacing: '4%',
                color: 'white'
              }}
              id='exampleModalLabel'
            >
              Filter
            </h5>
            <i
              className='fa fa-times'
              onClick={onFilterClose}
              style={{
                fontSize: 25,
                color: 'white',
                alignSelf: 'flex',
                padding: '2px 5px 2px 5px',
                margin: 0
              }}
              aria-hidden='true'
            />
          </div>
          <div
            className='modal-body'
            style={{ maxHeight: '90vh', overflowY: 'auto' }}
          >
            {filterData?.length !== 0 &&
          filterData?.map((item, itemIndex) => {
            if (item.shape === 'square') {
              return (
                <div
                  className='col-md-12'
                  key={String(itemIndex)}
                >
                  <div
                    className='month-tab'
                    style={{ borderColor: itemIndex === filterData.length - 1 ? 'white' : '' }}
                  >
                    <p className='mb-2'>{item.heading}</p>
                    <div
                      className='tabs'
                    >
                      {item.options.map((option, optionIndex) => {
                        return (
                          <a
                            style={{ cursor: 'pointer' }}
                            key={String(optionIndex)}
                            data-value={option.value}
                            data-type='duration'
                            onClick={() => item.setValue(option.value)}
                            className={
                              `d-flex align-items-center justify-content-center my-1 
                              
                              ${Array.isArray(item.value) && item?.value?.includes(option.value) ? 'custom-active' : ''}
                              ${typeof item.value === 'string' && item?.value === option.value ? 'custom-active' : ''}
                              
                              `
                            }
                          >
                            {option.label}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }
            if (item.shape === 'rounded') {
              return (
                <div
                  className='col-md-12'
                  key={String(itemIndex)}
                >
                  <div className='remark-tab'>
                    <p className='mb-2'>{item.heading}</p>
                    <div
                      className='tabs' style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}
                    >
                      {item.options.map((option, optionIndex) => {
                        return (
                          <a
                            style={{
                              cursor: 'pointer',
                              ...(item.value.includes(option.value) && { color: 'white' })
                            }}
                            key={String(optionIndex)}
                            data-value={option.value}
                            data-type='remark'
                            onClick={() => item.setValue(option.value)}
                            className={
                              item.value.includes(option.value) ? 'remark-active' : ''
                            }
                          >
                            {option.label}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }
            return <div key={itemIndex}>OOPS! something went wrong.</div>
          })}

            {customUI}
          </div>

          <div className='modal-footer '>
            <button
              type='button'
              onClick={onResetApply}
              className='btn btn-secondary'
              data-dismiss='modal'
            >
              Reset
            </button>
            <button
              type='button'
              onClick={onFiltersApply}
              className='btn btn-primary'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filter

// ================================================================================================
// import React from 'react'
// import closeIcon from '../../assets/img/close-icon1.svg'

// const Filter = (props) => {
//   const onFiltersApply = () => {
//     props.onFiltersApply()
//   }

//   return (
//     <>
//       <div className={`overlay-filter ${props.isFilterVisible ? '' : 'd-none'}`} />
//       <div className={`filter-bar ${props.isFilterVisible ? '' : 'd-none'}`}>
//         <div style={{ height: '100%', position: 'relative' }}>
//           <div className='row'>
//             <div className='col-md-12 ps-0 pe-0'>
//               <div className='header'>
//                 <ul className='mb-0 ps-0'>
//                   <li className='me-auto font-bold font-20 filter-title'>Filter</li>
//                   <li onClick={props.onFilterClose}>
//                     <img
//                       className='img-fluid'
//                       alt='icon'
//                       src={closeIcon}
//                     />
//                   </li>
//                 </ul>
//               </div>
//             </div>
//             {props?.filterData?.length !== 0 &&
//               props?.filterData?.map((item, itemIndex) => {
//                 if (item.shape === 'square') {
//                   return (
//                     <div
//                       className='col-md-12'
//                       key={String(itemIndex)}
//                     >
//                       <div className='month-tab'>
//                         <p className='mb-2'>{item.heading}</p>
//                         <div className='tabs'>
//                           {item.options.map((option, optionIndex) => {
//                             return (
//                               <a
//                                 style={{ cursor: 'pointer' }}
//                                 key={String(optionIndex)}
//                                 data-value={option.value}
//                                 data-type='duration'
//                                 onClick={() => item.setValue(option.value)}
//                                 className={
//                                   item.value.includes(option.value) ? 'custom-active' : ''
//                                 }
//                               >
//                                 {option.label}
//                               </a>
//                             )
//                           })}
//                         </div>
//                       </div>
//                     </div>
//                   )
//                 }
//                 if (item.shape === 'rounded') {
//                   return (
//                     <div
//                       className='col-md-12'
//                       key={String(itemIndex)}
//                     >
//                       <div className='remark-tab'>
//                         <p className='mb-2'>{item.heading}</p>
//                         <div className='tabs'>
//                           {item.options.map((option, optionIndex) => {
//                             return (
//                               <a
//                                 style={{
//                                   cursor: 'pointer',
//                                   ...(item.value.includes(option.value) && { color: 'white' })
//                                 }}
//                                 key={String(optionIndex)}
//                                 data-value={option.value}
//                                 data-type='remark'
//                                 onClick={() => item.setValue(option.value)}
//                                 className={
//                                   item.value.includes(option.value) ? 'remark-active' : ''
//                                 }
//                               >
//                                 {option.label}
//                               </a>
//                             )
//                           })}
//                         </div>
//                       </div>
//                     </div>
//                   )
//                 }
//                 return <div key={itemIndex}>OOPS! someting went wrong.</div>
//               })}
//             {/* {
//               props.enabledFilters.durations &&
//               <div className="col-md-12">
//                 <div className="month-tab">
//                   <p className="mb-2">Duration</p>
//                   <div className="tabs">
//                     {
//                       durations.map((duration, index) => {
//                         return (
//                           <a data-value={duration.value} data-type="duration" onClick={filterSelectionHandler} href="#" className={activeDuration == duration.value ? 'custom-active' : ''}>{duration.name}</a>
//                         )
//                       })
//                     }
//                   </div>
//                 </div>
//               </div>
//             }
//             {
//               props.enabledFilters.mrCodes &&
//               <div className="col-md-12">
//                 <div className="month-tab">
//                   <p className="mb-2 mr-title">MR Code</p>
//                   <div className="tabs">
//                     {
//                       mrCodes.map((mrCode, index) => {
//                         return (
//                           <a data-value={mrCode.value} data-type="mr-code" onClick={filterSelectionHandler} href="#" className={activeMRCode == mrCode.value ? 'custom-active' : ''}>{mrCode.name}</a>
//                         )
//                       })
//                     }
//                   </div>
//                 </div>
//               </div>
//             }
//             {props.enabledFilters.tariffs &&
//               <div className="col-md-12">
//                 <div className="tariff-tab">
//                   <p className="mb-2">Tariff</p>
//                   <div className="tabs">
//                     {
//                       tariffs.map((tariff, index) => {
//                         return (
//                           <a data-value={tariff.value} data-type="tariff" onClick={filterSelectionHandler} href="#" className={activeTariff == tariff.value ? 'custom-active' : ''}>{tariff.name}</a>
//                         )
//                       })
//                     }
//                   </div>
//                 </div>
//               </div>
//             }
//             {props.enabledFilters.tags &&
//               <div className="col-md-12">
//                 <div className="tariff-tab">
//                   <p className="mb-2">TC's</p>
//                   <div className="tabs">
//                     {
//                       tags.map((tag, index) => {
//                         return (
//                           <a data-value={tag.value} data-type="tag" onClick={filterSelectionHandler} href="#" className={activeTag == tag.value ? 'custom-active' : ''}>{tag.name}</a>
//                         )
//                       })
//                     }
//                   </div>
//                 </div>
//               </div>
//             }
//             {props.enabledFilters.remarks &&
//               <div className="col-md-12">
//                 <div className="remark-tab">
//                   <p className="mb-2">Remark</p>
//                   <div className="tabs">
//                     {
//                       remarks.map((remark, index) => {
//                         return (
//                           <a data-value={remark.value} data-type="remark" onClick={filterSelectionHandler} href="#" className={activeRemark == remark.value ? 'remark-active' : ''}>{remark.name}</a>
//                         )
//                       })
//                     }
//                   </div>
//                 </div>
//               </div>
//             } */}
//           </div>
//           <div
//             className='main-reset-apply-section'
//             style={props.footerStyle}
//           >
//             <div className='reset-apply-btn text-right pe-3'>
//               <button
//                 className='reset-btn'
//                 onClick={props.onResetApply}
//               >
//                 Reset
//               </button>
//               <button
//                 className={`btn btn-primary profile-active-btn  ${
//                   props?.disableApply ? '' : 'btn-grey'
//                 }`}
//                 disabled={!!props?.disableApply}
//                 onClick={onFiltersApply}
//               >
//                 Apply
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Filter
