import Chips from '../../atoms/Chips/Chips'
import styles from './fileStatus.module.scss'
import React, { useState } from 'react'
import downloadIcon from '../../../assets/img/file_download-24px.png'
import ResultItem from './ResultItem'
import arrow from '../../../assets/img/down-arrow.svg'
import dayjs from 'dayjs'
import corporateOfficeIcon from '../../../assets/img/corporate-office.png'
import { fetchUserDesignation } from '../../../services/utils/meta'
import cautionIcon from '../../../assets/img/caution.png'
import chevronRight from '../../../assets/img/chevron-right.png'
import SectionWiseResults from '../SectionWiseResults/SectionWiseResults'

const FileStatus = ({
  status = 'Default Status',
  fileId = 1234567891011,
  uploadedOn = new Date(),
  index = 1,
  message = 'There seems to be an error with our system!',
  count = {},
  onProcessingResultDownload,
  disableProcessedResult,
  uploadedBy = 'AE',
  onClickFailureRows,
  onClickSkippedRows,
  sectionResult = undefined,
  displaySectionResultButton = false
}) => {
  const statusMessagesArray = ['PROCESSING_FAILED', 'VALIDATION_FAILED', 'INELIGIBLE']
  const uploadedTimeStamp = dayjs(uploadedOn).format('DD/MM/YYYY, hh:mm a')

  const RenderChips = {
    AWAITING_PROCESSING: <Chips text='Awaiting Processing' color={styles.awaitingProcessing} />,
    VALIDATING: <Chips text='Validating' color={styles.validating} />,
    VALIDATION_FAILED: <Chips text='Validation failed' color={styles.validationFailed} />,
    PROCESSING: <Chips text='Processing' color={styles.processing} />,
    PROCESSED: <Chips text='Processed' color={styles.processed} />,
    PROCESSING_FAILED: <Chips text='Processing failed' color={styles.processingFailed} />,
    INELIGIBLE: <Chips text='Ineligible' color={styles.ineligible} />

  }

  const successCount = isNaN(Number(count.success)) ? '-' : Number(count.success)
  const failureCount = isNaN(Number(count.failure)) ? '-' : Number(count.failure)
  const skippedCount = isNaN(Number(count.skipped)) ? '-' : Number(count.skipped)
  const allRowsCount = isNaN(Number(successCount + failureCount + skippedCount)) ? '---' : Number(successCount + failureCount + skippedCount)

  const [open, setOpen] = useState(false)
  const [sectionResultsVisible, setSectionResultsVisibility] = useState(false)

  const modifiedMessage = message?.split('***') ?? []

  const loggedInUserDesignation = fetchUserDesignation()

  // CIO should not see the files uploaded by him in gold border and badged crown
  if (loggedInUserDesignation === 'cio') {
    uploadedBy = 'AE'
  }

  return (
    <div className={styles.root} uploadedBy={uploadedBy} style={{ paddingBottom: status === 'PROCESSED' ? 15 : 25 }}>
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <span className={styles.index}>{index}</span>
          <div>File Id : <span className={styles.lightText}>{fileId ?? '----'}</span></div>
          <div>Uploaded on : <span className={styles.lightText}>{uploadedTimeStamp ?? '----'}</span> </div>
        </div>
        <span className='ms-auto d-flex'>
          {loggedInUserDesignation === 'cio' && status === 'PROCESSED' && sectionResult?.ineligible?.length > 0 &&
            <img src={cautionIcon} alt='Caution Icon' className={styles.cautionIcon} />}
          <div className='status ms-2'>{RenderChips[status] ?? '----'}</div>
        </span>
      </div>
      {/* <div>{JSON.stringify(sectionResult)} {sectionResult?.ineligible?.length}</div> */}

      {uploadedBy === 'CIO' &&
        <div className={styles.crown}>
          <div>
            Uploaded by corporate office
          </div>
          <img src={corporateOfficeIcon} alt='Missing Icon' />
        </div>}

      {statusMessagesArray.includes(status) &&
        <div className={styles.validationFailure}>
          <div>
            <i className='fa fa-exclamation-circle' aria-hidden='true' />
          </div>

          <div>
            <div className={styles.errorHeading}>
              Issue : {modifiedMessage[0] ?? 'There seems to be an error with our system!1'}
            </div>
            {modifiedMessage.length > 1 &&
              <div className={styles.errorText}>
                <b>Solution</b> : {modifiedMessage[1]}
              </div>}
          </div>
        </div>}

      {
          (status === 'PROCESSED') &&
            <div className={`${styles.resultSectionContainer} ${open ? styles.open : ''}`}>
              <div className={styles.horizontalLine} />
              <div className={styles.smallHeading}>Result : </div>
              <div className={styles.resultStatusContainer}>
                <ResultItem type='all' number={allRowsCount} />
                <ResultItem type='success' number={successCount} />
                <ResultItem type='failure' number={failureCount} onClickInfo={onClickFailureRows} />
                <ResultItem type='skipped' number={skippedCount} onClickInfo={onClickSkippedRows} />
                <button
                  className='btn btn-primary btn-lg custom-active'
                  onClick={onProcessingResultDownload}
                  disabled={disableProcessedResult}
                >{disableProcessedResult ? 'Downloading...' : 'Processed result'}
                  {'\t'}
                  <img className='img-fluid download-icon' src={downloadIcon} alt='missing icon' />
                </button>
              </div>
              {displaySectionResultButton &&
                <div className={styles.sectionWiseButtonContainer}>
                  <div className={styles.sectionWiseButton} onClick={() => setSectionResultsVisibility(true)}>
                    <div className={styles.text}>Section wise results</div>
                    <img className={styles.icon} src={chevronRight} alt='Right Icon' />
                  </div>

                  {
                    sectionResult?.ineligible?.length > 0 &&
                      <div className={`text-center ${styles.warningText}`}>
                        ( {sectionResult.ineligible?.length} section(s) ineligible )
                      </div>
                  }
                </div>}
            </div>
        }
      {
          status === 'PROCESSED' &&

            <div
              className={`${styles.expand} ${styles.blinkingBorder}`}
              onClick={() => setOpen(!open)}
            >{open ? 'Show less' : 'Show more'}
              <img
                src={arrow}
                alt='Missing Icon'
                style={{
                  transform: `rotate(${open ? 180 : 0}deg)`,
                  transition: 'all 0.5s ease'
                }}
              />
            </div>

        }

      <SectionWiseResults
        fileId={fileId}
        visible={sectionResultsVisible}
        onClose={() => { setSectionResultsVisibility(false) }}
        onClickFailureRows={onClickFailureRows}
        onClickSkippedRows={onClickSkippedRows}
      />
    </div>

  )
}

export default FileStatus
