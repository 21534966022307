import React, { Fragment, useContext, useEffect, useState } from 'react'
import transformerImage from '../../../assets/img/transfarmer-icon.png'
import plusImage from '../../../assets/img/plus.svg'
import TCDetailComponent from './SubComponents/TCDetail'
import AddTCComponent from './SubComponents/AddTC'
import searchIcon from '../../../assets/img/search-tc-icon.svg'
import downIcon from '../../../assets/img/other-main-down-arrow.svg'
import upIcon from '../../../assets/img/other-main-up-arrow.svg'
// import deleteIcon from '../../../assets/img/delete-white.png'
import editIcon from '../../../assets/img/edit-white.png'
import FullScreenLoader from '../../../components/atoms/FullScreenLoader'
import {
  // deleteTC,
  exportTCs,
  getTCs
} from '../../../services/api/tcService'
import { NotifierContext } from '../../../context/NotifierContext'
import { AuditMonthContext } from '../../../context/AuditMonthContext'
import EditTC from './SubComponents/EditTC'
import { Colors } from '../../../theme/colors'
import Filter from '../../Shared/Filter/Filter'
import { readingDay } from '../../../assets/offlineData/TCOfflineData'
import { getMRs } from '../../../services/api/mrService'
import InfiniteScroll from 'react-infinite-scroll-component'
import './tc.css'
import Spinner from '../../../components/atoms/Spinner'
import { checkIfUserIsAE } from '../../../services/utils/meta'
import { HierarchyContext } from '../../../context/HierarchyContext'
import DownloadModal from '../../../components/atoms/Modal/Modal'

const TCDetail = () => {
  const isUserAE = checkIfUserIsAE()
  const notifier = useContext(NotifierContext)
  const { auditMonthYear } = useContext(AuditMonthContext)
  const { selectedHierarchy } = useContext(HierarchyContext)

  const [transformers, setTransformers] = useState([])
  const [MRs, setMRs] = useState([])
  const [activeItem, setActiveItem] = useState(0)
  const [activeFormKey, updateActiveFormKey] = useState('tc_details')
  const [selectedTC, setSelectedTC] = useState({})
  const [loading, setLoading] = useState(true)
  // const [deleteTCLoader, setDeleteTCLoader] = useState('')
  const [searchString, setSearchString] = useState('')
  const [pageData, setPageData] = useState({
    lastPage: 0,
    currentPage: 0,
    nextPage: null,
    previousPage: null,
    limit: 20,
    skip: 0
  })

  const [tcCount, setTCCount] = useState('')
  const [exportLoading, setExportLoading] = useState(false)

  /// /////////////////////Filter States//////////////////////
  const [filterVisible, setFilterVisibility] = useState(false)
  const [selectedReadingDay, setSelectedReadingDay] = useState([])
  const [selectedMR, setSelectedMR] = useState([])

  const [downloadPopupVisible, setDownloadPopupVisibility] = useState(false)
  const [selectedDownloadOption, setSelectedDownloadOption] = useState('all')
  const filterData = [
    {
      heading: 'Reading Day (s)',
      shape: 'square',
      options: [
        ...readingDay.map((item) => {
          return { label: item, value: item }
        })
      ],
      value: selectedReadingDay,
      setValue: (selectedValue) => {
        selectedReadingDay.includes(selectedValue)
          ? setSelectedReadingDay(selectedReadingDay.filter((item) => item !== selectedValue))
          : setSelectedReadingDay([...selectedReadingDay, selectedValue])
      }
    }
    // ...(isUserAE
    //   ? [
    //       {
    //         heading: 'Reading MR',
    //         shape: 'square',
    //         options: [...MRs],
    //         value: selectedMR,
    //         setValue: (selectedValue) => {
    //           selectedMR.includes(selectedValue)
    //             ? setSelectedMR(selectedMR.filter((item) => item !== selectedValue))
    //             : setSelectedMR([...selectedMR, selectedValue])
    //         }
    //       }
    //     ]
    //   : [])
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchTCs(1)
      isUserAE && fetchMRs()
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [searchString, auditMonthYear, selectedHierarchy])

  const setTransformerClickHandler = (index) => {
    if (activeItem === index) setActiveItem(-1)
    else setActiveItem(index)
  }

  const fetchMRs = async () => {
    try {
      const response = await getMRs('', 1, 100)
      setMRs(
        response.data.MRList.map((item) => {
          return { label: item.userInfo.mrCode, value: item.userInfo.mrCode }
        })
      )
    } catch (err) {
      notifier.notifyError(err)
    }
  }

  const fetchTCs = async (pageNumber) => {
    try {
      setLoading(true)
      const response = await getTCs(
        pageNumber,
        10,
        auditMonthYear,
        searchString,
        selectedReadingDay,
        selectedMR,
        selectedHierarchy
      )
      setTransformers(response.data.transformers)
      setTCCount(response.data.tcCount)
      setSelectedTC(response.data.transformers[0])
      setActiveItem(0)
      setPageData(response.data.pageData)
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const resetFilter = async () => {
    try {
      setSelectedReadingDay([])
      setSelectedMR([])
      setLoading(true)
      const response = await getTCs(1, 10, auditMonthYear, searchString, '', '', selectedHierarchy)
      setTransformers(response.data.transformers)
      setTCCount(response.data.tcCount)
      setSelectedTC(response.data.transformers[0])
      setActiveItem(0)
      setPageData(response.data.pageData)
      setLoading(false)
      setFilterVisibility(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  // const delTC = async (transformerId) => {
  //   setDeleteTCLoader(transformerId)
  //   const confirmation = window.confirm('Are you sure you want to delete this TC?')
  //   try {
  //     if (confirmation) {
  //       const response = await deleteTC(transformerId, auditMonthYear)
  //       fetchTCs(1)
  //       notifier.notifySuccess(response.message)
  //     }
  //   } catch (err) {
  //     notifier.notifyError(err)
  //   } finally {
  //     setDeleteTCLoader('')
  //   }
  // }

  const addTCFormHandler = (event) => {
    updateActiveFormKey('tc_add')
  }

  const tcDetailsScreenHandler = (event) => {
    updateActiveFormKey('tc_details')
  }

  const renderEmptyPageComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '88vh',
          fontSize: 24
        }}
      >
        No TC Selected
      </div>
    )
  }

  const handleLazyLoading = async () => {
    try {
      // setLoading(true)
      const pageNumber = pageData.currentPage + 1
      const response = await getTCs(
        pageNumber,
        10,
        auditMonthYear,
        searchString,
        selectedReadingDay,
        selectedMR,
        selectedHierarchy
      )
      setTransformers([...transformers, ...response.data.transformers])
      setTCCount(response.data.tcCount)
      // setSelectedTC(response.data.transformers[0])
      setActiveItem(0)
      setPageData(response.data.pageData)
      // setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      // setLoading(false)
    }
  }

  const exportHandler = async () => {
    try {
      setExportLoading(true)
      notifier.notifySuccess('Preparing for download. It may take few seconds. Please wait...')
      const response = await exportTCs(
        auditMonthYear,
        searchString,
        selectedReadingDay,
        selectedMR,
        selectedHierarchy,
        selectedDownloadOption
      )
      window.location.href = response.data
      notifier.notifySuccess('Download started.')
      setExportLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setExportLoading(false)
    }
  }

  const handleDownloadOptionChange = ({ target: { value } }) => {
    setSelectedDownloadOption(value)
  }

  return (
    <>
      <Filter
        footerStyle={{ position: 'absolute' }}
        filterData={filterData}
        onFiltersApply={() => {
          fetchTCs(1)
          setFilterVisibility(false)
        }}
        isFilterVisible={filterVisible}
        onFilterClose={() => setFilterVisibility(false)}
        onResetApply={resetFilter}
      />
      <DownloadModal
        okAction={exportHandler}
        title='TC file download'
        visible={downloadPopupVisible}
        onModalClose={() => setDownloadPopupVisibility(false)}
      >
        <div
          style={{ cursor: 'pointer' }}
          onClick={handleDownloadOptionChange}
        >
          <input
            value='all'
            checked={selectedDownloadOption === 'all'}
            className='form-check-input'
            type='radio'
            style={{ cursor: 'pointer' }}
            id='all'
          />
          &nbsp;{' '}
          <label
            className='form-check-label'
            htmlFor='all'
            style={{ cursor: 'pointer' }}
          >
            Download all TCs
          </label>
        </div>
        <div onClick={handleDownloadOptionChange}>
          <input
            checked={selectedDownloadOption === 'feeder-wise'}
            className='form-check-input'
            type='radio'
            style={{ cursor: 'pointer' }}
            value='feeder-wise'
            id='feeder-wise'
          />
          &nbsp;{' '}
          <label
            className='form-check-label'
            htmlFor='feeder-wise'
            style={{ cursor: 'pointer' }}
          >
            Download all TCs feeder-wise
          </label>
        </div>
      </DownloadModal>
      <div className='my-installation-content tc-audit-content audit-list-box'>
        <div className='container pt-0 pl-0'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='tc-audit-no-list'>
                <div className='add-new-tc-no pt-1 pb-1'>
                  <card className='collapse-text search-collpase-text'>
                    <img
                      className=''
                      src={searchIcon}
                      alt='missing icon'
                    />
                    <input
                      value={searchString}
                      onChange={(e) => {
                        setSearchString(e.target.value)
                      }}
                      type='text'
                      placeholder='TC Number'
                      className='form-control'
                    />
                  </card>
                </div>
                <div className='add-new-tc-no pt-3 pb-3 ps-2 pe-2'>
                  <card className='collapse-text'>
                    <div className='mb-0 tc-audit-no-title ms-1 me-auto'>
                      <p
                        className={`mb-0 tc-no-text font-regular-400 ${
                          isUserAE ? 'd-block' : 'd-none'
                        }`}
                        onClick={addTCFormHandler}
                      >
                        Add New TC{'\t'}
                        <img
                          className='img-fluid'
                          src={plusImage}
                          alt='missing icon'
                        />
                      </p>
                    </div>
                    <div className='delete-tc-audit me-3'>
                      <a onClick={() => setFilterVisibility(true)}>
                        Filter
                        {/* <i className="fa fa-filter ms-1" aria-hidden="true"></i> */}
                      </a>
                    </div>
                  </card>
                </div>
                <div
                  className='add-new-tc-no pt-3 pb-3 ps-2 pe-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <span>Total TCs - {tcCount?.toLocaleString('en-IN')}</span>
                  <span className='ms-6'>
                    {exportLoading
                      ? (
                        <Spinner />
                        )
                      : (
                        <i
                          className='fa fa-download'
                          aria-hidden='true'
                          style={{ color: Colors.primaryColor }}
                          onClick={() => {
                            setDownloadPopupVisibility(true)
                          }}
                        >
                          <span style={{ fontFamily: 'sans-serif', paddingLeft: 5 }}>Download</span>
                        </i>
                        )}
                  </span>
                </div>
                <InfiniteScroll
                  className='tcList'
                  dataLength={transformers.length}
                  loader={<h6 style={{ textAlign: 'center' }}>Loading...</h6>}
                  height='80vh'
                  hasMore={pageData.currentPage < pageData.lastPage}
                  next={handleLazyLoading}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all.</b>
                    </p>
                  }
                >
                  {loading
                    ? (
                      <FullScreenLoader />
                      )
                    : transformers.length === 0
                      ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 350
                          }}
                        >
                          No transformers found.
                        </div>
                        )
                      : (
                          transformers.map((transformer, index) => {
                            return (
                              <React.Fragment key={transformer.number}>
                                <div
                                  onClick={() => {
                                    setTransformerClickHandler(index)
                                    setSelectedTC(transformer)
                                  }}
                                  className='tc-audit-no-collapse pt-3 pb-3 ps-2 pe-2'
                                >
                                  <card
                                    className='collapse-text'
                                    data-bs-toggle='collapse'
                                    href='#collapseTcAuditNo-1'
                                    role='button'
                                    aria-expanded='false'
                                    aria-controls='collapseExample'
                                  >
                                    <img
                                      className='img-fluid'
                                      src={transformerImage}
                                      alt='missing icon'
                                    />
                                    <div className='mb-0 tc-audit-no-title ms-2'>
                                      <p className='mb-1 tc-no roboto font-medium-500'>
                                        TC No: {transformer.number}
                                      </p>
                                      <p
                                        className='mb-0 tc-no-text roboto font-light-300'
                                        title={`${transformer.name
                                    .charAt(0)
                                    .toUpperCase()}${transformer.name.substr(1).toLowerCase()}`}
                                      >
                                        {`${transformer.name.charAt(0).toUpperCase()}${transformer.name
                                    .substr(1)
                                    .toLowerCase()}`}
                                      </p>
                                      {!isUserAE && (
                                        <p
                                          className='mb-0 tc-no-text roboto font-light-300'
                                          title={transformer.location?.sectionCode ?? '--'}
                                        >
                                          Section Code : {transformer.location?.sectionCode ?? '--'}
                                        </p>)}
                                    </div>
                                    <div className='delete-tc-audit'>
                                      {/* {index == activeItem && <>
                                  <a onClick={() => updateActiveFormKey('edit_tc')} className="trash-tc" style={{ marginRight: 5 }}>
                                    <img className="img-fluid fa" src={editIcon} />
                                  </a>
                                  <a className="trash-tc" onClick={() => delTC(transformer.id)}>
                                    <img className="img-fluid fa" src={deleteIcon} /></a>
                                </>} */}
                                      <img
                                        className='img-fluid fa'
                                        src={`${index === activeItem ? upIcon : downIcon}`}
                                        alt='missing icon'
                                      />
                                      {/* <i className={`fa fa-angle-${index == activeItem ? 'up' : 'down'}`} aria-hidden="true"></i> */}
                                    </div>
                                  </card>
                                </div>
                                <div
                                  className={`collapse collapse-detail ${
                              index === activeItem ? 'show' : ''
                            }`}
                                  id='collapseTcAuditNo-1'
                                >
                                  <div className='card card-body border-0 pe-2 ps-2 pt-2 pb-2'>
                                    <div className='tc-installation-loss text-center mt-3 mb-2'>
                                      <span className='me-2'>
                                        <label className='number'>{`${
                                    transformer?.installationCount?.toLocaleString('en-IN') ?? 'N/A'
                                  }`}
                                        </label>{' '}
                                        Installations
                                      </span>
                                      <span>
                                        <label className='number'>
                                          {transformer?.currentLoss ?? 'N/A'}
                                        </label>{' '}
                                        Loss
                                      </span>
                                    </div>
                                    <ul className='mb-0 p-3'>
                                      {/* <li><b className="roboto font-bold">TC Code:</b> 3456</li> */}
                                      <li>
                                        <b className='roboto font-bold'>Reading MR :</b>{' '}
                                        {transformer?.readingMR?.mrCode}
                                      </li>
                                      <li>
                                        <b className='roboto font-bold'>Tagged MR(s) :</b>{' '}
                                        {transformer?.taggedMRs.map((item) => `${item.mrCode} `)}
                                      </li>
                                      <li>
                                        <b className='roboto font-bold'>CT Ratio :</b>{' '}
                                        {transformer?.meter?.ctRatio?.primaryCurrent} /{' '}
                                        {transformer?.meter?.ctRatio?.secondaryCurrent}
                                      </li>
                                      <li>
                                        <b className='roboto font-bold'>Meter Constant :</b>{' '}
                                        {transformer?.meter?.meterConstant}
                                      </li>
                                    </ul>
                                    <div
                                      style={{
                                        display: isUserAE ? 'flex' : 'none',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-evenly'
                                      }}
                                    >
                                      <div
                                        onClick={() => updateActiveFormKey('edit_tc')}
                                        className='trash-tc'
                                        style={{
                                          padding: '5px 0px 5px 10px',
                                          borderRadius: 5,
                                          width: 150,
                                          // marginRight: 5,
                                          background: Colors.secondaryColor,
                                          color: 'white',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <div className='roboto font-medium-500'>
                                          Edit TC
                                        </div>
                                        <img
                                          className='img-fluid'
                                          src={editIcon}
                                          alt='missing icon'
                                          style={{ height: 14, width: 14, marginLeft: 10 }}
                                        />
                                      </div>
                                      {/* <button
                                        className='trash-tc'
                                        disabled={transformer.id === deleteTCLoader}
                                        onClick={() => delTC(transformer.id)}
                                        style={{
                                          padding: '2px 35px 5px 35px',
                                          borderRadius: 5,
                                          background: Colors.secondaryColor,
                                          borderColor: Colors.secondaryColor
                                        }}
                                      >
                                        {transformer.id === deleteTCLoader
                                          ? <Spinner variant='white' />
                                          : <img
                                              className='img-fluid fa'
                                              src={deleteIcon}
                                              style={{ height: 20, width: 15, alignSelf: 'center' }}
                                            />}
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                          })
                        )}
                  {/* <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <Paginator
                      currentPage={pageData.currentPage}
                      lastPage={pageData.lastPage}
                      onPressNext={() => { fetchTCs(pageData.currentPage + 1) }}
                      onPressPrevious={() => { fetchTCs(pageData.currentPage - 1) }}
                    />
                  </div> */}
                </InfiniteScroll>
              </div>
            </div>
            <div className='col-md-9'>
              {activeFormKey === 'tc_details' &&
                (selectedTC?.number
                  ? (
                    <TCDetailComponent tcData={selectedTC} />
                    )
                  : (
                      renderEmptyPageComponent()
                    ))}
              {activeFormKey === 'tc_add' && (
                <AddTCComponent
                  onCloseButton={tcDetailsScreenHandler}
                  onAdd={() => fetchTCs(1)}
                />
              )}
              {activeFormKey === 'edit_tc' &&
                (selectedTC?.number
                  ? (
                    <EditTC
                      tcData={selectedTC}
                      onCloseButton={tcDetailsScreenHandler}
                      onEdit={() => fetchTCs(1)}
                    />
                    )
                  : (
                      renderEmptyPageComponent()
                    ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TCDetail
