import React from 'react'
import styles from './refreshButton.module.scss'

const RefreshButton = ({ onRefresh, loading }) => {
  return (
    <button
      className={`btn btn-outline-primary ${styles['refresh-btn']} `}
      onClick={onRefresh}
    >
      Refresh status
      <spin className={`${loading ? styles.spin : ''}`}>
        <i className='fa fa-refresh' aria-hidden='true' />
      </spin>

    </button>
  )
}

export default RefreshButton
