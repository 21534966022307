import React from 'react'
import './auditMonthFilter.scss'
import DatePicker from 'react-datepicker'

const AuditMonthFilter = ({ selectedMonth, onChangeMonth }) => {
  return (
    <div className='auditMonthFilterContainer'>
      <div className='header'>Audit month-year</div>
      <div className='inputContainer'>
        <DatePicker
          dateFormat='MMMM yyyy'
          showMonthYearPicker
          showFullMonthYearPicker
          showThreeColumnMonthYearPicker
          placeholderText='Select month-year'
          selected={selectedMonth}
          onChange={onChangeMonth}
        />
        {selectedMonth &&
          <div
            className='clear-button'
            onClick={() => onChangeMonth('')}
          >Clear Selection X
          </div>}
      </div>
    </div>
  )
}

export default AuditMonthFilter
