import React, { createContext, useState } from 'react'

export const HierarchyContext = createContext()

const HierarchyProvider = ({ children }) => {
  const [selectedHierarchy, setSelectedHierarchy] = useState('All')

  return (
    <HierarchyContext.Provider value={{ selectedHierarchy, setSelectedHierarchy }}>
      {children}
    </HierarchyContext.Provider>
  )
}

export default HierarchyProvider
