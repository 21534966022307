import React, { useState } from 'react'
// import { Route, Switch, Redirect } from 'react-router-dom'
// import FileManagement from './FileManagement'
import Header from '../components/molecules/Header/Header'
import Sidebar from '../screens/Shared/Sidebar'
import CioNavigator from './CioNavigator'
import { fetchUserDesignation } from '../services/utils/meta'
import AeNavigator from './AeNavigator'
import DtToAeeNavigator from './DtToAeeNavigator'

const DashboardWrapper = ({ signInSuccessHandler, isLoggedIn }) => {
  const [sideBarMinimized, setSideBarPosition] = useState(false)

  const sidebarHandler = () => {
    setSideBarPosition(!sideBarMinimized)
  }
  const sectionClasses = [
    'common-scroll-content',
    sideBarMinimized ? 'common-toggle-content' : ''
  ].join(' ')

  const loggedInUserDesignation = fetchUserDesignation()

  const allowedUsers = ['cio', 'dt', 'ce', 'se', 'ee', 'aee', 'ae']
  const dtToAeeArray = ['dt', 'se', 'ce', 'se', 'ee', 'aee']

  return (
    <>
      {allowedUsers.includes(loggedInUserDesignation)
        ? (
          <>
            <Header
              sidebarHandler={sidebarHandler}
              sideBarMinimized={sideBarMinimized}
              signoutHandler={signInSuccessHandler}
            />
            <div className='main-container'>
              <Sidebar sideBarMinimized={sideBarMinimized} />
              <div className={sectionClasses}>
                {loggedInUserDesignation === 'cio' && <CioNavigator />}
                {loggedInUserDesignation === 'ae' && <AeNavigator />}
                {dtToAeeArray.includes(loggedInUserDesignation) && <DtToAeeNavigator />}
              </div>
            </div>
          </>
          )
        : <h1>Unauthorized user! Please Contact support</h1>}
    </>
  )
}

export default DashboardWrapper
