import React from 'react'
import { Colors } from '../../../theme/colors'
import { useHistory } from 'react-router'

const NoResource = () => {
  const history = useHistory()

  return (
    <div
      className='d-flex flex-column align-items-center' style={{
        position: 'absolute',
        top: '30%',
        left: '50%'
      }}
    >
      <img
        alt='Lost eyes gif'
        src='https://cdn.pixabay.com/animation/2022/12/20/03/45/03-45-09-865_512.gif'
        style={{ width: 175, height: 100 }}
      />
      <h2
        className='roboto font-medium-500 mb-2 mt-5'
        style={{ color: Colors.secondaryColor }}
      >
        Looks like you're lost
      </h2>
      <h4 className='roboto font-light-300'>Resource Not found</h4>
      <div
        onClick={() => history.push('/')}
        className='tc-number mt-4'
        style={{
          maxWidth: 150,
          background: Colors.primaryColor,
          cursor: 'pointer'
        }}
      >
        Back to home
      </div>
    </div>
  )
}

export default NoResource
