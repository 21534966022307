import { API } from 'aws-amplify'
import { fetchUserDesignation } from '../utils/meta'

export const fetchAEProfileDetails = () => {
  const userDesignation = fetchUserDesignation()
  return API.get('baseURL', `/${userDesignation}/profile`)
}

export const updateProfileDetails = (body) => {
  return API.patch('baseURL', '/ae/profile', {
    body
  })
}
