import { Auth } from 'aws-amplify'
// import { hierarchyDetails } from '../../assets/offlineData/hierarchyData'

export const fetchSectionAndDivisionCode = async (inputData) => {
  const response = await Auth.currentAuthenticatedUser()
  if (inputData === 'sectionCode') { return response.attributes?.['custom:sectionCode'] ?? ' Error in fetching details' }
  if (inputData === 'subDivisionCode') { return response.attributes?.['custom:subDivisionCode'] ?? ' Error in fetching details' }
  throw new Error('Error in fetching details')
}

export const checkIfUserIsAE = () =>
  JSON.parse(window.localStorage.getItem('userAttributes'))?.['custom:userGroup'] === 'ae'

// used to fetch currently logged in user designation
export const fetchUserDesignation = () =>
  JSON.parse(window.localStorage.getItem('userAttributes'))?.['custom:userGroup']

export const fetchUserAttributes = () => JSON.parse(window.localStorage.getItem('userAttributes'))
