import React from 'react'

const FullScreenLoader = () => {
  return (
    <div style={styles.root}>
      <div className='d-flex justify-content-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
    minHeight: '88vh',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default FullScreenLoader
