import { fetchUserDesignation } from '../../services/utils/meta'

export const fileStatusOptions = [

  {
    label: 'Processed',
    value: 'PROCESSED'
  },
  {
    label: 'Validating',
    value: 'VALIDATING'
  },
  {
    label: 'Validation failed',
    value: 'VALIDATION_FAILED'
  },
  {
    label: 'Processing failed',
    value: 'PROCESSING_FAILED'
  },
  {
    label: 'Awaiting Processing',
    value: 'AWAITING_PROCESSING'
  },
  {
    label: 'Processing',
    value: 'PROCESSING'
  },
  {
    label: 'Ineligible',
    value: 'INELIGIBLE'
  }

]

export const uploadedByOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Corporate Office',
    value: 'CIO'
  },
  {
    label: 'AE',
    value: 'AE'
  }
]

export const getFileHistoryFilterOptions = (
  fileStatusValue,
  fileStatusValueSetter,
  uploadedByValue,
  uploadedByValueSetter
) => {
  const loggedInUserDesignation = fetchUserDesignation()
  const modifiedFileStatusOptions = [...fileStatusOptions].filter(option => {
    if (loggedInUserDesignation !== 'ae') {
      return option.label !== 'Disqualified'
    }
    return true
  }
  )

  return [
    {
      heading: 'File Status',
      shape: 'square',
      options: modifiedFileStatusOptions,
      value: fileStatusValue,
      setValue: fileStatusValueSetter
    },
    ...(loggedInUserDesignation === 'ae'
      ? [{

          heading: 'Uploaded By',
          shape: 'square',
          options: uploadedByOptions,
          value: uploadedByValue,
          setValue: uploadedByValueSetter
        }]
      : [])
  ]
}
