import React, { Fragment, useState, useEffect, useContext } from 'react'
import backArrow from '../../../assets/img/back-arrow.png'
import 'react-datepicker/dist/react-datepicker.css'
import { NotifierContext } from '../../../context/NotifierContext'
import {
  filterInstallations,
  getInstallations,
  getLossAnalysisMetrics
} from '../../../services/api/tcService'
import Paginator from '../../../components/molecules/Paginator'
import { AuditMonthContext } from '../../../context/AuditMonthContext'
import { useHistory, useLocation } from 'react-router'
import ViewTC from './ViewTC'
import { Colors } from '../../../theme/colors'
import TableMock from './InstallationsTable'
import Spinner from '../../../components/atoms/Spinner'
import { HierarchyContext } from '../../../context/HierarchyContext'

const filterStyle = {
  cardStyle: {
    border: `2px solid ${Colors.secondaryColor}`,
    color: Colors.secondaryColor,
    borderRadius: 5
  },
  countStyle: {
    color: Colors.secondaryColor
  }
}

const TCAuditDetail = () => {
  const notifier = useContext(NotifierContext)
  const { auditMonthYear, auditMonth } = useContext(AuditMonthContext)
  const { selectedHierarchy } = useContext(HierarchyContext)

  const location = useLocation()
  const history = useHistory()

  const tcData = location?.state?.tcData
  /// ///////////////////////////////////////////////////////
  const [viewTCVisible, setViewTCVisibility] = useState(false)
  const [lossAnalysisMetrics, setLossAnalysisMetrics] = useState({
    unbilled: '--',
    vacant: '--',
    zeroConsumption: '--',
    doorLock: '--',
    mnr: '--',
    abnormal: '--',
    subnormal: '--',
    billCancellation: '--'
  })
  const [metricsLoader, setMetricsLoader] = useState(false)

  /// ////////////////Installation States/////////////////////
  const [installations, setInstallations] = useState([])
  const [installationsCount, setInstallationsCount] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [loading, setLoading] = useState(true)
  const [perPage] = useState(10)
  const [pageData, setPageData] = useState({
    lastPage: 0,
    currentPage: 0,
    nextPage: null,
    previousPage: null,
    limit: 20,
    skip: 0
  })

  /// ///////////////////////////////////////Filter states/////////////////////////////////////////////////
  const [filterRemarks, setFilterRemarks] = useState([])

  /// /////////////////////Function to execute upon initial load and change in pagination and audit Month-Year/////////////
  useEffect(() => {
    fetchInstallations()
    fetchLossAnalysisMetrics()
  }, [pageNumber, auditMonthYear])

  /// /////////////////////Function to execute upon initial load and change in search and tcData/////////////
  useEffect(() => {
    const timer = setTimeout(() => {
      setPageNumber(1)
      fetchInstallations()
    }, 500)
    return () => {
      clearTimeout(timer)
    }
  }, [tcData, searchString])

  /// ////////////////////////////////////Function to fetch Loss Analysis Metrics/////////////////////////////////

  const fetchLossAnalysisMetrics = async () => {
    try {
      setMetricsLoader(true)
      const metrics = await getLossAnalysisMetrics(tcData.id, auditMonthYear, selectedHierarchy)
      setMetricsLoader(false)
      setLossAnalysisMetrics({ ...metrics.data })
    } catch (err) {
      notifier.notifyError(err)
      setMetricsLoader(false)
    }
  }

  /// ////////////////////////////////Function to fetch Installations//////////////////////////
  const fetchInstallations = async () => {
    try {
      setLoading(true)
      const response = await getInstallations(
        tcData.id,
        auditMonthYear,
        pageNumber,
        perPage,
        searchString,
        selectedHierarchy
      )
      setInstallations(response.data.installations)
      setPageData(response.data.pageData)
      setInstallationsCount(response.data.count)
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const getTCConsumption = () => {
    const consumption = tcData.audit?.filter(
      (item) =>
        new Date(item.month).getMonth() === auditMonth.getMonth() &&
        new Date(item.month).getFullYear() === auditMonth.getFullYear()
    )
    return consumption?.[0]?.reading?.consumption ?? '----'
  }

  const getTotalInstallationsConsumption = () => {
    return (
      tcData?.audit?.filter(
        (item) =>
          new Date(item.month).getFullYear() === new Date(auditMonth).getFullYear() &&
          new Date(item.month).getMonth() === new Date(auditMonth).getMonth()
      )?.[0]?.installationConsumption ?? '----'
    )
  }

  const getLossPercentage = () => {
    const getLossPercentage = tcData.audit?.filter(
      (item) =>
        new Date(item.month).getMonth() === auditMonth.getMonth() &&
        new Date(item.month).getFullYear() === auditMonth.getFullYear()
    )
    return getLossPercentage?.[0]?.lossPercentage ?? '----'
  }

  const getLossAnalysisData = () => {
    let auditData = tcData.audit?.filter(
      (item) =>
        new Date(item.month).getMonth() === auditMonth.getMonth() &&
        new Date(item.month).getFullYear() === auditMonth.getFullYear()
    )

    auditData = auditData ?? []

    if (auditData.length === 0 || auditData[0].lossAnalysis === undefined) {
      return {
        arr: '----',
        atAndC: '----',
        demand: '----',
        collection: '----',
        billingEfficiency: '----',
        collectionEfficiency: '----'
      }
    }
    return {
      arr: Math.round(auditData[0].lossAnalysis?.ARR * 100) / 100 ?? '----',
      atAndC: Math.round(auditData[0].lossAnalysis?.ATAndC * 100) / 100 ?? '----',
      demand: Math.round(auditData[0].lossAnalysis?.demand * 100) / 100 ?? '----',
      collection: Math.round(auditData[0].lossAnalysis?.collection * 100) / 100 ?? '----',
      billingEfficiency:
        Math.round(auditData[0].lossAnalysis?.billingEfficiency * 100) / 100 ?? '----',
      collectionEfficiency:
        Math.round(auditData[0].lossAnalysis?.collectionEfficiency * 100) / 100 ?? '----'
    }
  }

  const viewTCHandler = () => {
    setViewTCVisibility(true)
  }

  /// ////////////////////////////////////Handle Filter Tab Selection(Special Case. To be changed)///////////////////////////////
  const handleFilterTabSelection = async (value) => {
    console.log(value)
    filterRemarks.includes(value) ? setFilterRemarks([]) : setFilterRemarks([value])
    try {
      setLoading(true)
      const filterValue = filterRemarks.includes(value) ? [] : [value]

      // If selected value is either of abnormal, subnormal, billCancellation billingStatus should be []  if not billing should selected value
      const billingStatus =
        filterValue[0] === 'abnormal' ||
        filterValue[0] === 'subnormal' ||
        filterValue[0] === 'billCancellation'
          ? []
          : filterValue

      // If selected value is either of abnormal, subnormal, billCancellation variant should be selected value if not variant should be ''
      const variant =
        filterValue[0] === 'abnormal' ||
        filterValue[0] === 'subnormal' ||
        filterValue[0] === 'billCancellation'
          ? filterValue[0]
          : ''

      const response = await filterInstallations(
        tcData.id,
        auditMonthYear,
        pageNumber,
        perPage,
        billingStatus,
        [],
        variant,
        selectedHierarchy
      )
      setInstallations(response.data.installations)
      setPageData(response.data.pageData)
      setInstallationsCount(response.data.count)
    } catch (err) {
      notifier.notifyError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='tc-audit-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-12'>
                  {/* /////////////////////////////////////////////CONSUMPTION AND PERCENTAGE LOSS/////////////////////////////// */}
                  <div className='row mt-4'>
                    <div className='col-md-12'>
                      <div className='comman-head'>
                        <div className='tc-name-title'>
                          <h1 className='mb-0'>TC No. {tcData.number}</h1>
                        </div>
                        <div className='comman-head justify-content-end'>
                          <div className='back-btn'>
                            <a
                              onClick={() => history.goBack()}
                              className='btn btn-primary'
                            >
                              <img
                                src={backArrow}
                                alt='missing icon'
                                style={{ width: '24px', marginRight: '10px' }}
                              />
                              <p className='mb-0 me-1 font-regular-400'>BACK</p>
                            </a>
                          </div>
                          <div className='back-btn ms-3'>
                            <a
                              onClick={viewTCHandler}
                              className='btn btn-primary'
                            >
                              <p className='mb-0 me-1 font-regular-400'>View TC</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-12 consumption-point'>
                      <hr className='horizontal-line' />
                      <div className='consumption-load-section mb-2'>
                        <span className='mt-1 font-light-300 col-md-3'>
                          TC Consumption : <b>{getTCConsumption()?.toLocaleString('en-IN')}</b>
                          {/* <img className="img-fluid mb-2 ms-3" src={exclamation} /> */}
                        </span>
                        <hr className='vertical-line mx-4 mt-2 col-md-1' />
                        <span className='mt-1 font-light-300 col-md-4'>
                          Installations Consumption : <b>{getTotalInstallationsConsumption()}</b>
                          {/* <img className="img-fluid mb-2 ms-3" src={exclamation} /> */}
                        </span>
                        <hr className='vertical-line mx-4 mt-2 col-md-1' />
                        <span className='mt-1 font-light-300 col-md-3'>
                          Percentage Loss : <b>{getLossPercentage()}</b>
                          {/* <img className="img-fluid mb-2 ms-3" src={exclamation}
                            title={`LOSS DETAILS\nMNR : ${lossAnalysisMetrics.mnr ?? "---"}\nDoorLock : ${lossAnalysisMetrics.doorLock ?? "---"}\nZero Consumption :  ${lossAnalysisMetrics.zeroConsumption ?? "---"}\nVacant : ${lossAnalysisMetrics.vacant ?? "---"}\nUnbilled : ${lossAnalysisMetrics.unbilled ?? "---"}`} /> */}
                        </span>
                      </div>
                    </div>

                    <div className='col-md-12 consumption-point'>
                      <hr
                        className='horizontal-line'
                        style={{ margin: 0 }}
                      />
                      <h6
                        className='my-3 font-medium-700'
                        style={{ fontWeight: 'bold' }}
                      >
                        Revenue parameters
                      </h6>
                      <div className='consumption-load-section mb-2 d-flex flex-wrap'>
                        {/* ///////////////////////////////////////// */}
                        <span className='mt-1 font-light-300 col-md-3'>
                          ARR : <b>{getLossAnalysisData()?.arr?.toLocaleString('en-IN')}</b>
                        </span>
                        <hr className='vertical-line mx-4 mt-2 col-md-1' />
                        <span className='mt-1 font-light-300 col-md-3'>
                          AT&C : <b>{getLossAnalysisData().atAndC}%</b>
                        </span>
                        <hr className='vertical-line mx-4 mt-2 col-md-1' />
                        <span className='mt-1 font-light-300 col-md-3'>
                          Demand : <b>₹{getLossAnalysisData()?.demand?.toLocaleString('en-IN')}</b>
                        </span>
                        {/* <hr className="vertical-line mx-4 mt-2 col-md-1" /> */}

                        {/* ////////////////////////////////// */}
                      </div>
                      <hr
                        className='horizontal-line'
                        style={{ margin: 0 }}
                      />

                      <div className='consumption-load-section mb-2 d-flex flex-wrap'>
                        <span className='mt-3 font-light-300 col-md-3'>
                          Collection :{' '}
                          <b>₹{getLossAnalysisData()?.collection?.toLocaleString('en-IN')}</b>
                        </span>
                        <hr className='vertical-line mx-4 mt-4 col-md-1' />
                        <span className='mt-3 font-light-300 col-md-3'>
                          Billing Efficiency : <b>{getLossAnalysisData().billingEfficiency}%</b>
                        </span>
                        <hr className='vertical-line mx-4 mt-4 col-md-1' />
                        <span className='mt-3 font-light-300 col-md-3'>
                          Collection Efficiency :{' '}
                          <b>{getLossAnalysisData().collectionEfficiency}%</b>
                        </span>
                      </div>
                    </div>
                    <div className='col-md-12 consumption-point'>
                      <hr
                        className='horizontal-line'
                        style={{ margin: 0 }}
                      />
                    </div>
                    {/* /////////////////////////////////////////////Loss Analysis//////////////////////////////// */}
                    <h6
                      className='my-3 font-medium-700'
                      style={{ fontWeight: 'bold' }}
                    >
                      Loss Analysis
                    </h6>
                    <div className='row top-transformer-tab'>
                      <div className='col-md-3 mb-2 '>
                        <div className='card custom-card-tc-5'>
                          <div
                            className='cp card-body align-items-center'
                            onClick={(e) => {
                              handleFilterTabSelection('UNBILLED')
                            }}
                            style={filterRemarks[0] === 'UNBILLED' ? filterStyle.cardStyle : {}}
                          >
                            <p className='card-text mb-0 me-auto font-regular-400'>Unbilled</p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={filterRemarks[0] === 'UNBILLED' ? filterStyle.countStyle : {}}
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics.unbilled?.toLocaleString('en-IN') ?? '---'}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-2 '>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            style={filterRemarks[0] === 'VACANT' ? filterStyle.cardStyle : {}}
                            onClick={(e) => {
                              handleFilterTabSelection('VACANT')
                            }}
                          >
                            <p className='card-text mb-0 me-auto font-regular-400'>Vacant</p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={filterRemarks[0] === 'VACANT' ? filterStyle.countStyle : {}}
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics.vacant?.toLocaleString('en-IN') ?? '---'}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-2 '>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            onClick={(e) => {
                              handleFilterTabSelection('ZERO_CONSUMPTION')
                            }}
                            style={
                              filterRemarks[0] === 'ZERO_CONSUMPTION' ? filterStyle.cardStyle : {}
                            }
                          >
                            <p className='mb-0 me-auto font-regular-400'>
                              Zero Consumption
                            </p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={
                                filterRemarks[0] === 'ZERO_CONSUMPTION'
                                  ? filterStyle.countStyle
                                  : {}
                              }
                            >
                              {metricsLoader
                                ? (
                                  <Spinner />
                                  )
                                : (
                                    lossAnalysisMetrics.zeroConsumption?.toLocaleString('en-IN') ?? '---'
                                  )}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-2 '>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            style={filterRemarks[0] === 'MNR' ? filterStyle.cardStyle : {}}
                            onClick={(e) => {
                              handleFilterTabSelection('MNR')
                            }}
                          >
                            <p className='card-text mb-0 me-auto font-regular-400'>MNR</p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={filterRemarks[0] === 'MNR' ? filterStyle.countStyle : {}}
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics?.mnr?.toLocaleString('en-IN') ?? '---'}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-2 '>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            style={filterRemarks[0] === 'DOOR_LOCKED' ? filterStyle.cardStyle : {}}
                            onClick={(e) => {
                              handleFilterTabSelection('DOOR_LOCKED')
                            }}
                          >
                            <p className='card-text mb-0 me-auto font-regular-400'>Doorlock</p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={
                                filterRemarks[0] === 'DOOR_LOCKED' ? filterStyle.countStyle : {}
                              }
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics.doorLock?.toLocaleString('en-IN') ?? '---'}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-2 '>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            style={filterRemarks[0] === 'abnormal' ? filterStyle.cardStyle : {}}
                            onClick={(e) => {
                              handleFilterTabSelection('abnormal')
                            }}
                          >
                            <p className=' mb-0 me-auto font-regular-400'>Abnormal Installations</p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={filterRemarks[0] === 'abnormal' ? filterStyle.countStyle : {}}
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics.abnormal?.toLocaleString('en-IN')}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-4'>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            style={filterRemarks[0] === 'subnormal' ? filterStyle.cardStyle : {}}
                            onClick={(e) => {
                              handleFilterTabSelection('subnormal')
                            }}
                          >
                            <p className='mb-0 me-auto font-regular-400'>Subnormal Installations</p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={
                                filterRemarks[0] === 'subnormal' ? filterStyle.countStyle : {}
                              }
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics.subnormal?.toLocaleString('en-IN')}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-3 mb-4'>
                        <div className='card custom-card-tc-6'>
                          <div
                            className='cp card-body align-items-center'
                            style={
                              filterRemarks[0] === 'billCancellation' ? filterStyle.cardStyle : {}
                            }
                            onClick={(e) => {
                              handleFilterTabSelection('billCancellation')
                            }}
                          >
                            <p className='mb-0 me-auto font-regular-400'>
                              Bill Cancellation
                            </p>
                            <hr />
                            <h4
                              className='mb-0 font-medium-500'
                              style={
                                filterRemarks[0] === 'billCancellation'
                                  ? filterStyle.countStyle
                                  : {}
                              }
                            >
                              {metricsLoader ? <Spinner /> : lossAnalysisMetrics.billCancellation?.toLocaleString('en-IN')}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ////////////////////////////////Add and Edit TC Reading////////////////////////////// */}
                    <div className='col-md-12'>
                      <div className='main-tc-name-form mb-3'>

                        {/* //////////////////////////////////////////////Installations/////////////////////////////////////////////// */}
                        <div className='installation-list list-view-box'>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className='digit-installation-title mb-3'>
                              <h3 className='mb-0 ms-3 font-medium-500'>
                                {installationsCount?.toLocaleString('en-IN')} Installations
                              </h3>
                            </div>
                            <div className='digit-installation-title mb-3'>
                              <h3
                                className='mb-0 ms-3 font-medium-500'
                                style={{ textTransform: 'uppercase' }}
                              >
                                {filterRemarks[0]
                                  ? filterRemarks[0] === 'billCancellation'
                                    ? 'BILL CANCELLATION'
                                    : filterRemarks[0].replace(/_/g, ' ')
                                  : ''}
                              </h3>
                            </div>
                          </div>
                          <div className='search-heading mb-4 pl-0'>
                            <div className='search-space position-relative me-auto ms-3'>
                              <input
                                type='text'
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                                placeholder='RR Number'
                                className='form-control search-field'
                              />
                            </div>
                          </div>
                          <div className='installing-list'>
                            <div className='table-responsive'>
                              <TableMock
                                loading={loading}
                                installations={installations}
                                perPage={perPage}
                                pageData={pageData}
                                selected={filterRemarks[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <nav
                    className='float-end'
                    aria-label='Page navigation example'
                  >
                    <Paginator
                      currentPage={pageData.currentPage}
                      lastPage={pageData.lastPage}
                      onPressNext={() => {
                        setPageNumber((prevState) =>
                          prevState >= pageData.lastPage ? prevState : prevState + 1
                        )
                      }}
                      onPressPrevious={() => {
                        setPageNumber((prevState) => (prevState <= 1 ? prevState : prevState - 1))
                      }}
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewTC
        visible={viewTCVisible}
        onModalClose={() => {
          setViewTCVisibility(false)
        }}
        tcData={tcData}
      />
    </>
  )
}

export default TCAuditDetail
