import React, { useState, useContext, useEffect } from 'react'
import UploadSection from '../../../../components/molecules/UploadSection/UploadSection'
import Button from '../../../../components/atoms/Button'
import styles from './stationAndFeeders.module.scss'
import historyIcon from '../../../../assets/img/history.png'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getPresignedURL, uploadFile, getDownLoadProcessedResultUrl, getSampleFilePresignedURL, getUploadStatus } from '../../../../services/api/tcAuditService'
import { useHistory } from 'react-router-dom'
import RefreshButton from '../../../../components/atoms/RefreshButton/RefreshButton'
import InfoModal from '../../../../components/atoms/Modal/Modal'
import SampleFileModal from '../../../../components/atoms/SampleFileModal/SampleFileModal'

// import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'

const StationAndFeeders = () => {
  const [sampleFileOpen, setSampleFileOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const { notifyError, notifySuccess } = useContext(NotifierContext)
  const [filUploadLoader, setFileUploadLoader] = useState(false)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)
  const [sampleFileLoader, setSampleFileLoader] = useState(false)

  // const [pageData, setPageData] = useState({
  //   current: 1,
  //   last: 1
  // })

  const history = useHistory()

  useEffect(() => {
    fetchFileUploadStatus(1)
  }, [])

  const fetchFileUploadStatus = async (page) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'stationAndFeeders',
        page,
        perPage: 1,
        sortBy: 'uploadDate'
        // uploadedBy: 'AE'
      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      // setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSampleFileDownload = async () => {
    try {
      setSampleFileLoader(true)
      const response = await getSampleFilePresignedURL('stationAndFeeders')
      setSampleFileOpen(false)
      notifySuccess('File download has started...')
      window.location.href = response.data
    } catch (err) {
      notifyError(err)
    } finally {
      setSampleFileLoader(false)
    }
  }

  const handleFileUploading = async (event) => {
    try {
      setFileUploadLoader(true)

      const fileExtension = event.target.files[0].name.split('.').pop()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xlsb' && fileExtension !== 'xls') {
        throw new Error('This type of file is not allowed to upload. You can upload only files which have xls,xlsx,xlsb extensions')
      }

      const response = await getPresignedURL('stationAndFeeders')
      await uploadFile(event.target.files[0], response.data[fileExtension])
      notifySuccess('Successfully Uploaded the Power station and feeders report file.')
    } catch (err) {
      notifyError(err)
    } finally {
      setFileUploadLoader(false)
    }
  }

  // const handleLoadMore = () => {
  //   const nextPage = pageData.current + 1
  //   fetchFileUploadStatus(nextPage)
  // }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(fileId)
      const response = await getDownLoadProcessedResultUrl('stationAndFeeders', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  return (
    <div className='container'>
      <UploadSection
        fileName='Power station and feeders'
        hasSampleFile
        hasAuditMonth={false}
        sampleFileHandler={() => setSampleFileOpen(true)}
        handleFileUpload={handleFileUploading}
        fileUploadDisabled={filUploadLoader}
      />
      <div className='d-flex align-items-center justify-content-between mt-5 my-4'>
        <div className={styles.heading}>
          Latest file upload status (uploaded by me)
        </div>
        <RefreshButton
          onRefresh={() => { fetchFileUploadStatus(1) }}
          loading={loading}

        />

      </div>
      {loading
        ? <div>Loading....</div>
        : filesHistory.length === 0
          ? <div>No results found!</div>
          : (
            <>
              {filesHistory.map((file, index) => {
                return (
                  <div className={styles.fileStatusContainer} key={file.fileId}>
                    <FileStatus
                      index={index + 1}
                      status={file.status}
                      fileId={file.fileId}
                      uploadedOn={file.uploadedOn}
                      message={file.message}
                      onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                      disableProcessedResult={processedResultLoader === file.fileId}
                      count={{
                        success: file.count?.success ?? '-',
                        failure: file.count?.failure ?? '-',
                        skipped: file.count?.skipped ?? '-'
                      }}
                      onClickFailureRows={() => setModalVisibility('failure')}
                      onClickSkippedRows={() => setModalVisibility('skipped')}
                    />
                  </div>
                )
              })}
              {/* {!(pageData.current === pageData.last) &&
                <LoadMoreButton onClick={handleLoadMore} />} */}
            </>
            )}

      <div className={`${styles['btn-container']} mt-5`}>
        <Button
          className={`btn btn-light ${styles['upload-history-btn']}`}
          onClick={() => {
            history.push('/file-upload?tab=stationAndFeedersFileHistory')
          }}
        >
          View file upload history
          <img src={historyIcon} alt={styles['history-icon']} />
        </Button>
      </div>
      <SampleFileModal
        heading='Instructions - Power stations and feeders'
        okBtnDisabled={sampleFileLoader}
        open={sampleFileOpen}
        onClose={() => setSampleFileOpen(false)}
        onSubmit={handleSampleFileDownload}
      >
        Coming Soon!
        {/* <SampleFileInstructions /> */}
      </SampleFileModal>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'skipped' &&
          (
            <>
              <h3>Power stations</h3>
              1. Rows are skipped when there are duplicate rows i.e., when a power station with this code is already present<br />
              2. Rows are skipped when the power station is already present and there are no changes in the editable columns for that station<br /><br />
              <h3>Feeders</h3>
              1. Rows are skipped when there are duplicate rows i.e., when a feeder with this MDM code is already present<br />
              2. Rows are skipped when the feeder is already present and there are no changes in the editable columns for that feeder
              <br /><br />
            </>

          )}
        {modalVisible === 'failure' &&
          <>
            <h3>Feeders</h3>
            1. When the power station is not found in the system
            <br /><br />
          </>}
      </InfoModal>
    </div>
  )
}

export default StationAndFeeders
