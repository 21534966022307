import { hierarchyDetails } from '../../assets/offlineData/hierarchyData'

export const getHierarchyDataOfNextRank = (currentUserRank) => {
  return hierarchyDetails.find((item) => item.rank === currentUserRank + 1)
}

export const getHierarchyDataByProperty = (property, matchingProperty) => {
  return hierarchyDetails.find((item) => item[property] === matchingProperty)
}

export const getHierarchyDataOfPreviousRank = (currentUserRank) => {
  return hierarchyDetails.find((item) => item.rank === currentUserRank - 1)
}
