export const hierarchyDetails = [
  {
    designation: 'dt',
    name: 'Director Technical',
    rank: 1,
    area: 'ESCOM',
    areaName: 'ESCOM', // cognito enums
    areaCode: 'zoneCode', // cognito enums
    location: {
      name: 'zone',
      areaCode: 'zoneCode', // backend enums
      areaName: 'zoneName'
    }
  },
  {
    designation: 'cio',
    name: 'Corporate IT office',
    rank: 1,
    area: 'ESCOM',
    areaName: 'ESCOM', // cognito enums
    areaCode: 'zoneCode', // cognito enums
    location: {
      name: 'zone',
      areaCode: 'zoneCode', // backend enums
      areaName: 'zoneName'
    }
  },
  {
    designation: 'ce',
    name: 'Chief Engineer',
    rank: 2,
    area: 'Zone',
    areaName: 'zoneCode_name', // cognito enums,
    areaCode: 'zoneCode', // cognito enums
    location: {
      name: 'zone',
      areaCode: 'zoneCode',
      areaName: 'zoneName'
    }
  },
  {
    designation: 'se',
    name: 'Superintending Engineer',
    rank: 3,
    area: 'Circle',
    areaName: 'circleCode_name', // cognito enums,
    areaCode: 'circleCode', // cognito enums
    location: {
      name: 'circle',
      areaCode: 'circleCode',
      areaName: 'circleName'
    }
  },
  {
    designation: 'ee',
    name: 'Executive Engineer',
    rank: 4,
    area: 'Division',
    areaName: 'divisionCode_name', // cognito enums,
    areaCode: 'divisionCode', // cognito enums
    location: {
      name: 'division',
      areaCode: 'divisionCode',
      areaName: 'divisionName'
    }
  },

  {
    designation: 'aee',
    name: 'Assistant Executive Engineer',
    rank: 5,
    area: 'Sub-Division',
    areaName: 'subDivisionCode_name', // cognito enums,
    areaCode: 'subDivisionCode', // cognito enums
    location: {
      name: 'subDivision',
      areaCode: 'subDivisionCode',
      areaName: 'subDivisionName'
    }
  },
  {
    designation: 'ae',
    name: 'Assistant Engineer',
    rank: 6,
    area: 'Section',
    areaName: 'sectionCode_name', // cognito enums
    areaCode: 'sectionCode', // cognito enums
    location: {
      name: 'section',
      areaCode: 'sectionCode',
      areaName: 'sectionName'
    }
  }
]
